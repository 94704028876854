import {Injectable, inject} from '@angular/core';
import {IdTokenResult} from '@angular/fire/auth';
import {map, switchMap, take, tap} from 'rxjs';

import {RoleName as Roles} from '@common/constants';
import {Role} from '@common/types';
import {AuthService} from '@services';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  private readonly _auth = inject(AuthService);
  currentRole!: Role;

  constructor() {
    this.role.pipe(
      take(1),
      tap((role) => {
        this.currentRole = role;
      })
    ).subscribe();
  }

  get role2() {
    return this.currentRole;
  }

  get role() {
    return this._auth.user.pipe(
      switchMap((user) => user.getIdTokenResult()),
      map<IdTokenResult, Role>((result) => result.claims['role'])
    );
  }

  private _is(role: Role) {
    return this.role.pipe(map((_role) => _role === role));
  }

  hasRole2(roles: Role[]): boolean {
    return roles.includes(this.currentRole);
  }

  hasRole(roles: Role[]) {
    return this.role.pipe(map((role) => roles.includes(role)));
  }

  isAdmin() {
    return this._is(Roles.ADMIN);
  }

  isHHRR() {
    return this._is(Roles.HHRR);
  }

  isSeller() {
    return this._is(Roles.SELLER);
  }
}
