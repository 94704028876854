import {createAction, props} from '@ngrx/store';
import {APIResponseSettlements} from '@common/models';

const applyDateFilter = createAction('[GeneralLiquidationByEmployee] Seleccionar periodo Load', props<{id: string, year: number, month: number; page_number?: number; page_size?: number}>());
const applyDateFilterSuccess = createAction('[GeneralLiquidationByEmployee] Seleccionar periodo Success', props<{payload: APIResponseSettlements}>());
const applyDateFilterError = createAction('[GeneralLiquidationByEmployee] Seleccionar periodo Error', props<{payload: any}>());


const noop = createAction('[GeneralLiquidationByEmployee ->Noop]');

export const noAdminLiquidationsActions = {
  applyDateFilter,
  applyDateFilterSuccess,
  applyDateFilterError,

  noop
}
