import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { NgxTurnstileModule, NgxTurnstileFormsModule } from 'ngx-turnstile';

import { environment } from '@environments';
import { SignUpOrRecoveryMode as Mode } from '@common/constants';
import { BaseMixin } from '@components/_base';
import { RkButtonComponent } from '@components/shared/rk-button/rk-button.component';
import { RkInputComponent } from '@components/shared/rk-input/rk-input.component';
import { AlertService, AuthService } from '@services';

import {
  modalErrorMetadata,
  recoveryModalSuccessMetadata,
  signUpModalSuccessMetadata,
} from './sign-up-or-recovery.modal-metadata';

@Component({
  selector: 'app-sign-up-or-recovery',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgxTurnstileModule,
    NgxTurnstileFormsModule,
    ReactiveFormsModule,
    RkButtonComponent,
    RkInputComponent,
  ],
  templateUrl: './sign-up-or-recovery.component.html',
  styleUrls: ['./sign-up-or-recovery.component.scss']
})
export class SignUpOrRecoveryComponent extends BaseMixin implements OnInit {
  private readonly _alert = inject(AlertService);
  private readonly _auth = inject(AuthService);
  private readonly _formBuilder = inject(FormBuilder);
  private readonly _route = inject(ActivatedRoute);
  private readonly _router = inject(Router);

  forSignUp = true;
  siteKey = environment.captcha.publicKey;
  captcha = '';

  signUpOrRecoveryForm = this._formBuilder.nonNullable.group({
    email: ['', { validators: [Validators.required, Validators.email] }],
  });

  ngOnInit(): void {
    this.forSignUp = this._route.snapshot.url.at(0)?.path === 'sign-up';
  }

  signUpOrRecovery() {
    if (this.signUpOrRecoveryForm.valid && this.captcha) {
      const { email } = this.signUpOrRecoveryForm.getRawValue();

      this._setBusy();
      this._auth
        .signUpOrRecovery(email, this.captcha, this.forSignUp ? Mode.SIGN_UP : Mode.RECOVERY)
        .subscribe({
          next: () => {
            this._setIdle();

            const dialogRef = this._alert.showModal(
              this.forSignUp ? signUpModalSuccessMetadata : recoveryModalSuccessMetadata
            );

            dialogRef.afterClosed().subscribe(() => this._router.navigate(['auth/login']));
          },
          error: () => {
            this._setIdle();
            this._alert.showModal(modalErrorMetadata);
            this.captcha = '';
          },
        });
    }
  }
}
