export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyDGEN8PeSAfqRlwA-Z9wUwnalSm7To0_l4',
    authDomain: 'sza-preprod.firebaseapp.com',
    projectId: 'sza-preprod',
    storageBucket: 'sza-preprod.appspot.com',
    messagingSenderId: '359616739836',
    appId: '1:359616739836:web:821f3bd5653ebb85221a79',
  },
  tenantId: 'dev-rentokil-uhq2k',
  baseURL: 'https://dev-api.rentokil.seiza-ti.cl/api/v1/',
  endpoints: {
    auth: 'auth/',
    signUp: 'sign-up',
    recoveryPassword: 'recovery-password',
    pdf: {
      settlementsByEmployeesPDF: 'settlement/pdf',
      sellPayloadErrorPDF: 'upload/sales/errors',
    },
    csv: {
      settlementsByEmployeesCSV: 'settlement/csv',
      errorsCSV: 'upload/sales/errors/csv'
    }
  },
  captcha: {
    publicKey: '0x4AAAAAAAD86X67A-xK_VMd',
  },
  payloadEmployeeFb: 'rentokil-development-uploads-metadata',
  calc_log: 'rentokil-development-calc-log'
};
