import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';

import {ModalMetadata} from '@common/types';
import {RkButtonComponent} from '@components/shared/rk-button/rk-button.component';

@Component({
  selector: 'app-modal-alert',
  standalone: true,
  imports: [CommonModule, MatDialogModule, RkButtonComponent],
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalAlertComponent {
  private readonly dialog = inject(MatDialogRef);

  data: ModalMetadata = inject(MAT_DIALOG_DATA);

  close(result: unknown) {
    this.dialog.close(result);
  }
}
