<mat-form-field [class]="classes" [hideRequiredMarker]="hideRequiredMarker" [color]="color">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input matInput
         [id]="id"
         [formControl]="control"
         [type]="type"
         [placeholder]="placeholder"
         [readonly]="readonly"
         [mask]="mask"
  />
  <button
    *ngIf="forPassword"
    mat-icon-button
    matSuffix
    type="button"
    (click)="togglePasswordVisibility()"
    [attr.aria-label]="'Mostrar contraseña'"
    [attr.aria-pressed]="type !== 'password'"
    class="mr-2"
    tabindex="-1"
  >
    <mat-icon fontSet="material-symbols-outlined" color="primary">{{
      type === "password" ? "visibility" : "visibility_off"
    }}</mat-icon>
  </button>
  <!--<mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  <mat-error *ngIf="control.invalid && control.touched" class="text-right text-sm font-medium">{{
    errorText
  }}</mat-error>-->
  <div class="absolute translate-y-[15px]">
    <div class="text-[12px]">{{hint}}</div>
    <div  *ngIf="control.touched && control.invalid"
          class="text-[12px] text-red">{{errorText}}</div>
  </div>
</mat-form-field>
