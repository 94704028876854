import {createReducer, createSelector, on} from '@ngrx/store';
import {runProcessActions} from '@features/run-process/store/process.actions';
import {
  APIPeriodNextResponse,
  APIResponse,
  APIResponseForUser,
  APIResponsePeriodPending,
  APIResponseSettlements, APIResponseSettlementsAvailable,
  APIResponseSettlementsFilter,
  APIResponseSummary, APISettlementSummaryByEmployee,
  Comission,
  ProcessSummary,
  RunningProcess, UserDataAPI,
  UserProfile
} from '@common/models';
import {DocumentData} from '@angular/fire/firestore';
import {generalInformationActions} from '@features/general-information/store/general-information.actions';
import {sellPayloadsActions} from '@features/sell-payloads/store/sell-payloads.actions';
import {
  clearDocumentId,
  sellPayloadsEmployeeActions
} from '@features/sell-payloads-employee/store/sell-payloads-employee.actions';
import {sellPayloadsGoalsActions} from '@features/sell-payloads-goals/store/sell-payloads-goals.actions';
import {employeeInformationActions} from '@features/general-liquidation-by-employee/store/employee-information.actions';
import {
  sellerTypeCommisionSummaryActions
} from '@shared/components/seller-type-commission-summary/seller-type-comission-summary.actions';

export const runProcessFeatureKey = 'RunProcess';

export interface AppState {
  process: ProcessState,
}

export interface ProcessState {
  pending: {
    payload: APIResponsePeriodPending | null;
    loading: boolean;
  };
  logs: {
    payload: APIResponse<RunningProcess> | null;
    loading: boolean;
  };
  periodSummary: {
    payload: APIResponseForUser<ProcessSummary> | null;
    loading: boolean;
  };
  runProcess: {
    payload: APIResponse<any> | DocumentData | null;
    loading: boolean;
    documentReference: string;
    didSendToValidate: boolean;
  };
  validations: {
    payload: APIResponseSummary<Comission> | null;
    loading: boolean;
  };
  validationsSeller: {
    payload: APIResponseSettlements | null;
    loading: boolean;
    pageNumber: number;
  };
  validationsFilter: {
    payload: APIResponseSettlementsFilter | null;
    loading: boolean;
  };
  sellPayloads: {
    isUploading: boolean;
    processId: string;
    periodNext: {
      payload: APIPeriodNextResponse | null;
      loading: boolean;
    };
    sellNotes: {
      payload: any | null;
      loading: boolean;
    };
    sellNotesErrors: {
      payload: any | null;
      loading: boolean;
    }
  };
  sellPayloadsEmployee: {
    isUploading: boolean;
    processId: string;
    employees: {
      loading: boolean;
      payload: APIResponseForUser<UserDataAPI> | null
    };
  };
  sellPayloadsGoals: {
    isUploading: boolean;
    processId: string;
    employees: {
      payload: any | null;
      loading: boolean;
    }
  };
  employeeInformation: {
    loading: boolean;
    payload: APIResponseForUser<UserProfile> | null
  };
  settlementSummaryByEmployee: {
    loading: boolean;
    payload: APISettlementSummaryByEmployee | null;
  };
  settlementByEmployee: {
    loading: boolean;
    payload: APIResponseSettlements | null;
  };
  settlementsAvailable: {
    loading: boolean;
    payload: APIResponseSettlementsAvailable | null;
  };
}
export const initialState: ProcessState = {
  pending: {
    payload: null,
    loading: false
  },
  logs: {
    payload: null,
    loading: false
  },
  periodSummary: {
    payload: null,
    loading: false
  },
  runProcess: {
    payload: null,
    loading: false,
    documentReference: '',
    didSendToValidate: false
  },
  validations: {
    payload: null,
    loading: false
  },
  validationsSeller: {
    payload: null,
    loading: false,
    pageNumber: 0
  },
  validationsFilter: {
    payload: null,
    loading: false
  },
  sellPayloads: {
    isUploading: false,
    processId: '',
    periodNext: {
      payload: null,
      loading: false
    },
    sellNotes: {
      payload: null,
      loading: false
    },
    sellNotesErrors: {
      payload: null,
      loading: false
    }
  },
  sellPayloadsEmployee: {
    isUploading: false,
    processId: '',
    employees: {
      loading: false,
      payload: null
    },
  },
  sellPayloadsGoals: {
    isUploading: false,
    processId: '',
    employees: {
      loading: false,
      payload: null
    },
  },
  employeeInformation: {
    loading: false,
    payload: null
  },
  settlementSummaryByEmployee: {
    loading: false,
    payload: null
  },
  settlementByEmployee: {
    loading: false,
    payload: null
  },
  settlementsAvailable: {
    loading: false,
    payload: null
  }
}

export const runProcessReducer = createReducer(
  initialState,
  /** *********************************/
  on(runProcessActions.getPeriodDate, (state) => {
    // Set loading true for period pending endpoint
    return {
      ...state,
      pending: {
        payload: null,
        loading: true
      }
    }
  }),
  on(runProcessActions.getPeriodDateSuccess, (state, {payload}) => {
    // Save information of pending period
    return {
      ...state,
      pending: {
      ...state.pending,
        payload,
        loading: false
      }
    }
  }),
  on(runProcessActions.getPeriodDateError, (state, {payload}) => {
    // Save information of pending period
    return {
      ...state,
      pending: {
      ...state.pending,
        payload: null,
        loading: false
      }
    }
  }),
  on(runProcessActions.loadPeriodDate, (state) => {
    // Set loading true for period pending endpoint
    return {
      ...state,
      pending: {
        payload: null,
        loading: true
      }
    }
  }),
  on(runProcessActions.loadPeriodDateSuccess, (state, {payload}) => {
    // Save information of pending period
    return {
      ...state,
      pending: {
      ...state.pending,
        payload,
        loading: false
      }
    }
  }),
  on(runProcessActions.loadPeriodDateError, (state, {payload}) => {
    // Save information of pending period
    return {
      ...state,
      pending: {
      ...state.pending,
        payload: null,
        loading: false
      }
    }
  }),
  /** *********************************/
  on(runProcessActions.getProcessLogs, (state) => {
    // Set loading true for period pending endpoint
    return {
      ...state,
      logs: {
        payload: null,
        loading: true
      }
    }
  }),
  on(runProcessActions.getProcessLogsSuccess, (state, {payload}) => {
    // Save information of pending period
    return {
      ...state,
      logs: {
        ...state.logs,
        payload,
        loading: false
      }
    }
  }),
  /** *********************************/
  on(runProcessActions.getProcessPeriodSummary, (state) => {
    // Set loading true for period pending endpoint
    return {
      ...state,
      periodSummary: {
        payload: null,
        loading: true
      }
    }
  }),
  on(runProcessActions.getProcessPeriodSummarySuccess, (state, {payload}) => {
    // Save information of pending period
    return {
      ...state,
      periodSummary: {
        ...state.periodSummary,
        payload,
        loading: false
      }
    }
  }),
  /** *********************************/
  on(runProcessActions.runProcess, (state) => {
    // Set loading true for period pending endpoint
    return {
      ...state,
      runProcess: {
        ...state.runProcess,
        payload: null,
        loading: true
      }
    }
  }),
  on(runProcessActions.runProcessSuccess, (state, {payload}) => {
    // Save information of pending period
    return {
      ...state,
      runProcess: {
        ...state.runProcess,
        payload,
        loading: false
      }
    }
  }),
  on(runProcessActions.saveProcessSuccess, (state, {payload, documentReference}) => {
    // Save information of pending period
    return {
      ...state,
      runProcess: {
        ...state.runProcess,
        documentReference
      }
    }
  }),
  on(runProcessActions.clearDocumentReference, (state) => {
    // Save information of pending period
    return {
      ...state,
      runProcess: {
        ...state.runProcess,
        documentReference: ''
      }
    }
  }),
  // Mark true if send to validate was requested
  on(runProcessActions.sendProcessSuccess, (state) => {
    // Save information of pending period
    return {
     ...state,
      runProcess: {
      ...state.runProcess,
        didSendToValidate: true
      }
    }
  }),
  on(runProcessActions.sendProcessError, (state) => {
    // Save information of pending period
    return {
     ...state,
      runProcess: {
      ...state.runProcess,
        didSendToValidate: false
      }
    }
  }),

  // -----------------------------------------------------------------
  // Feature of General Information. TODO: move own feature
  on(generalInformationActions.loadValidations, (state) => {
    // Set loading true for period pending endpoint
    return {
      ...state,
      validations: {
        payload: null,
        loading: true
      }
    }
  }),
  on(generalInformationActions.loadValidationsSuccess, (state, {payload}) => {
    // Save information of pending period
    return {
      ...state,
      validations: {
        ...state.validations,
        payload,
        loading: false,
      }
    }
  }),
  on(generalInformationActions.loadValidationsError, (state) => {
    // Save information of pending period
    return {
      ...state,
      validations: {
        ...state.validations,
        loading: false
      }
    }
  }),


  on(generalInformationActions.loadValidationsSeller, (state) => {
    // Set loading true for period pending endpoint
    return {
      ...state,
      validationsSeller: {
        ...state.validationsSeller,
        payload: null,
        loading: true
      }
    }
  }),
  on(generalInformationActions.loadValidationsSellerSuccess, (state, {payload}) => {
    // Save information of pending period
    return {
      ...state,
      validationsSeller: {
        ...state.validationsSeller,
        payload,
        loading: false
      }
    }
  }),
  on(generalInformationActions.loadValidationsSellerError, (state) => {
    // Save information of pending period
    return {
      ...state,
      validationsSeller: {
        ...state.validationsSeller,
        loading: false
      }
    }
  }),
  on(generalInformationActions.loadValidationsSellerPaginate, (state, action) => {
    // Save information of pending period
    return {
      ...state,
      validationsSeller: {
        ...state.validationsSeller,
        pageNumber: action.payload
      }
    }
  }),


  on(generalInformationActions.loadValidationsFilter, (state) => {
    // Set loading true for period pending endpoint
    return {
      ...state,
      validationsFilter: {
        payload: null,
        loading: true
      }
    }
  }),
  on(generalInformationActions.loadValidationsFilterSuccess, (state, {payload}) => {
    // Save information of pending period
    return {
      ...state,
      validationsFilter: {
        ...state.validationsFilter,
        payload,
        loading: false
      }
    }
  }),
  on(generalInformationActions.loadValidationsFilterError, (state) => {
    // Save information of pending period
    return {
      ...state,
      validationsFilter: {
        ...state.validationsFilter,
        loading: false
      }
    }
  }),


  ////////////////Sellpayloads
  on(sellPayloadsActions.clearDocumentId, (state) => {
    return {
      ...state,
      sellPayloads: {
        ...state.sellPayloads,
        processId: ''
      }
    }
  }),
  on(sellPayloadsActions.upload, (state) => {
   return {
     ...state,
     sellPayloads: {
       ...state.sellPayloads,
       isUploading: true,
       processId: ''
     }
   }
  }),
  on(sellPayloadsActions.uploadSuccess, (state) => {
   return {
     ...state,
     sellPayloads: {
       ...state.sellPayloads,
       // isUploading: false // no necesariamente, puede quedar cargando hasta que el proceso de finished entonces se manda a parar
     }
   }
  }),
  on(sellPayloadsActions.loadSignUrlsSuccess, (state, {payload}) => {
    return {
      ...state,
      sellPayloads: {
        ...state.sellPayloads,
        // isUploading: false,
        processId: payload.urls.id
      }
    }
  }),
  on(sellPayloadsActions.uploadError, (state) => {
   return {
     ...state,
     sellPayloads: {
       ...state.sellPayloads,
       isUploading: false,
       processId: ''
     }
   }
  }),
// Notas de venta carga
  on(sellPayloadsActions.loadSellNotes, (state) => {
    return {
      ...state,
      sellPayloads: {
        ...state.sellPayloads,
        sellNotes: {
          payload: null,
          loading: true
        }
      }
    }
  }),
  on(sellPayloadsActions.loadSellNotesSuccess, (state, {payload}) => {
    return {
      ...state,
      sellPayloads: {
        ...state.sellPayloads,
        sellNotes: {
          payload,
          loading: false
        }
      }
    }
  }),
  on(sellPayloadsActions.loadSellNotesError, (state, {payload}) => {
    return {
      ...state,
      sellPayloads: {
        ...state.sellPayloads,
        sellNotes: {
          payload: null,
          loading: false
        }
      }
    }
  }),
// Notas de venta carga errores
  on(sellPayloadsActions.loadSellNotesErrors, (state) => {
    return {
      ...state,
      sellPayloads: {
        ...state.sellPayloads,
        sellNotesErrors: {
          payload: null,
          loading: true
        }
      }
    }
  }),
  on(sellPayloadsActions.loadSellNotesErrorsSuccess, (state, {payload}) => {
    return {
      ...state,
      sellPayloads: {
        ...state.sellPayloads,
        sellNotesErrors: {
          payload,
          loading: false
        }
      }
    }
  }),
  on(sellPayloadsActions.loadSellNotesErrorsError, (state, {payload}) => {
    return {
      ...state,
      sellPayloads: {
        ...state.sellPayloads,
        sellNotesErrors: {
          payload: null,
          loading: false
        }
      }
    }
  }),

  ////////////////Sellpayloads Employee
  on(sellPayloadsEmployeeActions.clearDocumentId, (state) => {
   return {
     ...state,
     sellPayloadsEmployee: {
       ...state.sellPayloadsEmployee,
       processId: ''
     }
   }
  }),
  on(sellPayloadsEmployeeActions.upload, (state) => {
   return {
     ...state,
     sellPayloadsEmployee: {
       ...state.sellPayloadsEmployee,
       isUploading: true,
       processId: ''
     }
   }
  }),
  on(sellPayloadsEmployeeActions.uploadSuccess, (state) => {
   return {
     ...state,
     sellPayloadsEmployee: {
       ...state.sellPayloadsEmployee,
       // isUploading: false // no necesariamente, puede quedar cargando hasta que el proceso de finished entonces se manda a parar
     }
   }
  }),
  on(sellPayloadsEmployeeActions.loadSignUrlsSuccess, (state, {payload}) => {
   return {
     ...state,
     sellPayloadsEmployee: {
       ...state.sellPayloadsEmployee,
       // isUploading: false,
       processId: payload.urls.id
     }
   }
  }),
  on(sellPayloadsEmployeeActions.uploadError, (state) => {
   return {
     ...state,
     sellPayloadsEmployee: {
       ...state.sellPayloadsEmployee,
       isUploading: false,
       processId: ''
     }
   }
  }),
  on(sellPayloadsEmployeeActions.loadEmployees, (state) => {
    return {
      ...state,
      sellPayloadsEmployee: {
        ...state.sellPayloadsEmployee,
        employees: {
          loading: true,
          payload: null
        }
      }
    }
  }),
  on(sellPayloadsEmployeeActions.loadEmployeesSuccess, (state, {payload}) => {
    return {
      ...state,
      sellPayloadsEmployee: {
        ...state.sellPayloadsEmployee,
        isUploading: false,
        employees: {
          loading: false,
          payload
        }
      }
    }
  }),
  on(sellPayloadsEmployeeActions.loadEmployeesError, (state, {payload}) => {
    return {
      ...state,
      sellPayloadsEmployee: {
        ...state.sellPayloadsEmployee,
        isUploading: false,
        employees: {
          loading: false,
          payload: null
        }
      }
    }
  }),


  ////////////////Sellpayloads Goals
  on(sellPayloadsGoalsActions.clearDocumentId, (state) => {
    return {
      ...state,
      sellPayloadsGoals: {
        ...state.sellPayloadsGoals,
        processId: ''
      }
    }
  }),
  on(sellPayloadsGoalsActions.upload, (state) => {
   return {
     ...state,
     sellPayloadsGoals: {
       ...state.sellPayloadsGoals,
       isUploading: true,
       processId: ''
     }
   }
  }),
  on(sellPayloadsGoalsActions.uploadSuccess, (state) => {
   return {
     ...state,
     sellPayloadsGoals: {
       ...state.sellPayloadsGoals,
       // isUploading: false // dejarlo pasar hasta el proceso finished
     }
   }
  }),
  on(sellPayloadsGoalsActions.loadSignUrlsSuccess, (state, {payload}) => {
   return {
     ...state,
     sellPayloadsGoals: {
       ...state.sellPayloadsGoals,
       // isUploading: false,
       processId: payload.urls.id
     }
   }
  }),
  on(sellPayloadsGoalsActions.uploadError, (state) => {
   return {
     ...state,
     sellPayloadsGoals: {
       ...state.sellPayloadsGoals,
       isUploading: false,
       processId: ''
     }
   }
  }),
  on(sellPayloadsGoalsActions.loadGoals, (state) => {
    return {
      ...state,
      sellPayloadsGoals: {
        ...state.sellPayloadsGoals,
        employees: {
          loading: true,
          payload: null
        }
      }
    }
  }),
  on(sellPayloadsGoalsActions.loadGoalsSuccess, (state, {payload}) => {
    return {
      ...state,
      sellPayloadsGoals: {
        ...state.sellPayloadsGoals,
        isUploading: false,
        employees: {
          loading: false,
          payload
        }
      }
    }
  }),
  on(sellPayloadsGoalsActions.loadGoalsError, (state, {payload}) => {
    return {
      ...state,
      sellPayloadsGoals: {
        ...state.sellPayloadsGoals,
        isUploading: false,
        employees: {
          loading: false,
          payload: null
        }
      }
    }
  }),


  /* Period Next*/
  on(sellPayloadsActions.periodNext, (state) => {
    // Set loading true for period pending endpoint
    return {
      ...state,
      sellPayloads: {
        ...state.sellPayloads,
        periodNext: {
         ...state.sellPayloads.periodNext,
          loading: true
        }
      }
    }
  }),
  on(sellPayloadsActions.periodNextSuccess, (state, {payload}) => {
    // Save information of pending period
    return {
      ...state,
      sellPayloads: {
        ...state.sellPayloads,
        periodNext: {
          payload,
          loading: false
        }
      }
    }
  }),
  on(sellPayloadsActions.periodNextError, (state) => {
    // Save information of pending period
    return {
      ...state,
      sellPayloads: {
        ...state.sellPayloads,
        periodNext: {
          ...state.sellPayloads.periodNext,
          loading: false
        }
      }
    }
  }),

  //----------------------------------------------------------------Employee Innformation Actions
  on(employeeInformationActions.loadEmployeeInformation, (state) => {
    // Save information of pending period
    return {
      ...state,
      employeeInformation: {
        ...state.employeeInformation,
        loading: false
      }
    }
  }),
  on(employeeInformationActions.loadEmployeeInformationError, (state) => {
    // Save information of pending period
    return {
      ...state,
      employeeInformation: {
        payload: null,
        loading: false
      }
    }
  }),
  on(employeeInformationActions.loadEmployeeInformationSuccess, (state, {payload}) => {
    // Save information of pending period
    return {
      ...state,
      employeeInformation: {
        payload,
        loading: false
      }
    }
  }),

  //---------------Employee Innformation: Settlement Commission Summary
  on(sellerTypeCommisionSummaryActions.loadSellerTypeCommissionSummary, (state) => {
    return {
      ...state,
      settlementSummaryByEmployee: {
        ...state.settlementSummaryByEmployee,
        loading: true
      }
    }
  }),
  on(sellerTypeCommisionSummaryActions.loadSellerTypeCommissionSummarySuccess, (state, {payload}) => {
    return {
      ...state,
      settlementSummaryByEmployee: {
        payload,
        loading: false
      }
    }
  }),
  on(sellerTypeCommisionSummaryActions.loadSellerTypeCommissionSummaryError, (state) => {
    return {
      ...state,
      settlementSummaryByEmployee: {
        payload: null,
        loading: false
      }
    }
  }),
  //---------------Employee Innformation: Settlement Sell Notes
  on(sellerTypeCommisionSummaryActions.loadSettlements, (state) => {
    return {
      ...state,
      settlementByEmployee: {
        ...state.settlementByEmployee,
        loading: true
      }
    }
  }),
  on(sellerTypeCommisionSummaryActions.loadSettlementsSuccess, (state, {payload}) => {
    return {
      ...state,
      settlementByEmployee: {
        payload,
        loading: false
      }
    }
  }),
  on(sellerTypeCommisionSummaryActions.loadSettlementsError, (state) => {
    return {
      ...state,
      settlementByEmployee: {
        payload: null,
        loading: false
      }
    }
  }),
//---------------Employee Innformation: Settlement Availables
  on(sellerTypeCommisionSummaryActions.loadSettlementsAvailables, (state) => {
    return {
      ...state,
      settlementsAvailable: {
        ...state.settlementsAvailable,
        loading: true
      }
    }
  }),
  on(sellerTypeCommisionSummaryActions.loadSettlementsAvailablesSuccess, (state, {payload}) => {
    return {
      ...state,
      settlementsAvailable: {
        payload,
        loading: false
      }
    }
  }),
  on(sellerTypeCommisionSummaryActions.loadSettlementsAvailablesError, (state) => {
    return {
      ...state,
      settlementsAvailable: {
        payload: null,
        loading: false
      }
    }
  }),
)

export const runProcessSelector = createSelector(
  (state: AppState) => state.process,
  (process: ProcessState) => process
)
