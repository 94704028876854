import { ModalMetadata } from '@common/types';
import { AlertType as ModalType } from '@common/constants';

export const signUpModalSuccessMetadata: ModalMetadata = {
  type: ModalType.INFO,
  title: 'La solicitud ha sido enviada a tu correo electrónico',
  text: 'Dirígete a tu bandeja de entrada y sigue las instrucciones para restablecer tu contraseña.',
  buttons: [{ label: 'Cerrar' }],
};

export const recoveryModalSuccessMetadata: ModalMetadata = {
  type: ModalType.INFO,
  title: 'La solicitud de recuperar contraseña ha sido enviada a tu correo electrónico',
  text: 'Dirígete a tu bandeja de entrada y sigue las instrucciones para restablecer tu contraseña.',
  buttons: [{ label: 'Cerrar' }],
};

export const modalErrorMetadata: ModalMetadata = {
  type: ModalType.ERROR,
  title: 'No pudimos enviar la solicitud',
  text: 'Tenemos problemas con la información otorgada, inténtalo de nuevo, si el problema persiste por favor comunícate con el administrador de la plataforma lo antes posible.',
  buttons: [{ label: 'Cerrar' }],
};
