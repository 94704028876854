import {Component, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ModalMetadata} from '@common/types';
import {RkButtonComponent} from '@components/shared/rk-button/rk-button.component';
import {JsonPipe, NgForOf, NgIf} from '@angular/common';

@Component({
  selector: 'details-msg',
  templateUrl: './details-msg.component.html',
  imports: [
    RkButtonComponent,
    NgIf,
    NgForOf,
    JsonPipe
  ],
  standalone: true
})
export default class DetailsMsgComponent {
  private readonly dialog = inject(MatDialogRef);

  data: ModalMetadata = inject(MAT_DIALOG_DATA);

  close(result: unknown) {
    this.dialog.close(result);
  }
}
