<ng-container *ngIf="{ loading: loading | async } as ctx">
  <button
    *ngIf="stroked; else flat"
    mat-stroked-button
    [color]="color"
    [type]="type"
    [class]="defaultClass +' '+ extraClasses"
    [disabled]="ctx.loading || disabled"
    [ngClass]="{ 'w-full': extended }"
    (click)="onClick()"
  >
    {{ label }}
    <mat-icon *ngIf="ctx.loading" class="!-ml-6 !mr-3 !h-5 !w-5">
      <mat-spinner diameter="20"></mat-spinner>
    </mat-icon>
  </button>
  <ng-template #flat>
    <button
      mat-flat-button
      [color]="color"
      [type]="type"
      [class]="defaultClass +' '+ extraClasses"
      [disabled]="ctx.loading || disabled"
      [ngClass]="{ 'w-full': extended }"
      (click)="onClick()"
    >
      {{ label }}
      <mat-icon *ngIf="ctx.loading" class="!-ml-6 !mr-3 !h-5 !w-5">
        <mat-spinner diameter="20"></mat-spinner>
      </mat-icon>
    </button>
  </ng-template>
</ng-container>
