<h1 *ngIf="forSignUp; else recovery" class="mb-10 text-center text-[28px]/[32px] font-bold">
  ¡Bienvenido! Ingresa tu correo electrónico
</h1>
<ng-template #recovery>
  <h1 class="mb-4 text-center text-[28px]/[32px] font-bold">Ayuda con la contraseña</h1>
  <p class="mb-9 text-center text-base/5 font-medium">
    Escribe la dirección de correo electrónico asociado a tu cuenta de Rentokil
  </p>
</ng-template>
<form [formGroup]="signUpOrRecoveryForm" (ngSubmit)="signUpOrRecovery()">
  <rk-input
    [type]="'email'"
    [id]="'submit-email'"
    [control]="signUpOrRecoveryForm.controls.email"
    [label]="'Correo electrónico'"
    [placeholder]="'usuario@rentokil.cl'"
  ></rk-input>
  <rk-button
    [type]="'submit'"
    [label]="'Continuar'"
    [extended]="true"
    [disabled]="!signUpOrRecoveryForm.valid || !captcha"
    [loading]="isBusy$"
  ></rk-button>
</form>
<div class="mt-5 flex justify-center">
  <ngx-turnstile [siteKey]="siteKey" theme="light" [(ngModel)]="captcha"></ngx-turnstile>
</div>
