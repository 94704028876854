import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AlertType } from '@common/constants';
import { BaseMixin } from '@components/_base';
import { RkButtonComponent } from '@components/shared/rk-button/rk-button.component';
import { RkInputComponent } from '@components/shared/rk-input/rk-input.component';
import { AlertService, AuthService } from '@services';
import { passwordMatchesValidator, passwordValidator } from '@validators';

import { modalErrorMetadata, modalSuccessMetadata } from './reset-password.modal-metadata';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RkButtonComponent, RkInputComponent],
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends BaseMixin implements OnInit {
  private readonly _alert = inject(AlertService);
  private readonly _auth = inject(AuthService);
  private readonly _formBuilder = inject(FormBuilder);
  private readonly _route = inject(ActivatedRoute);
  private readonly _router = inject(Router);

  resetPasswordForm = this._formBuilder.nonNullable.group({
    password: ['', { validators: [Validators.required, passwordValidator] }],
    confirmPassword: ['', { validators: [Validators.required, passwordMatchesValidator] }],
  });

  ngOnInit(): void {
    this._alert.showAlert({
      duration: 5000,
      type: AlertType.INFO,
      text: 'Felicidades, validamos tu correo electrónico',
    });
  }

  resetPassword() {
    if (this.resetPasswordForm.valid) {
      const { password } = this.resetPasswordForm.getRawValue();

      this._setBusy();

      this._auth
        .confirmPassword(this._route.snapshot.queryParams['oobCode'], password)
        .then(() => {
          const dialogRef = this._alert.showModal(modalSuccessMetadata);
          dialogRef.afterClosed().subscribe(() => this._router.navigate(['auth/login']));
        })
        .catch(() => this._alert.showModal(modalErrorMetadata))
        .finally(() => this._setIdle());
    }
  }
}
