<div>
  <span
    mat-dialog-close
    class="material-symbols-outlined m-2 ml-auto block w-fit cursor-pointer text-3xl/none text-slate-gray"
    (click)="close(false)">close</span>
  <div class="px-8">
    <div
      [class]="'rk-modal-content mb-5 w-[370px] rounded-xl px-7 py-5 text-charcoal-800 ' + data.extraCss"
      [ngClass]="[data.type]"
    >
      <span class="material-symbols-outlined mso-no-fill mx-auto mb-3 block w-fit text-3xl/none">{{
        data.type
      }}</span>
      <p class="mb-4 text-center text-lg/6 font-medium">
        {{ data.title }}
      </p>
      <p class="text-center">
        {{ data.text }}
      </p>
    </div>
  </div>
  <div *ngIf="data.buttons?.length" class="flex justify-center gap-4 p-6 pt-0">
    <rk-button
      *ngFor="let button of data.buttons"
      [label]="button.label"
      [stroked]="button.stroked!"
      (doClick)="close(button.result)"
      [extraClasses]="button.extraClasses ?? ''"
    >
    </rk-button>
  </div>
</div>
