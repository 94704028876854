import {inject} from '@angular/core';
import {catchError, exhaustMap, filter, map, of, switchMap} from 'rxjs';
import {Actions, concatLatestFrom, createEffect, ofType} from '@ngrx/effects';
import {ProcessService} from '../../../core/services/process.service';
import {runProcessActions} from '@features/run-process/store/process.actions';
import {ProcessRunningService} from '../../../core/services/process-running.service';
import {Store} from '@ngrx/store';
import {selectRunProcessDate} from '@features/run-process/store/process.selector';
import {ProcessStore} from '@features/run-process/ProcessStore';
import {AlertService} from '@services';
import {errorClosingIntervalMetadata} from '@features/run-process/notify/notify.modal.metadata';
import {genericErrorMetadata} from '@features/run-process/trigger-action/trigger.modal.metadata';
import {mapDialogErrors} from "../../../core/maps/maps-error-msg";



export const loadPeriodDateEffects = createEffect(
  (actions$ = inject(Actions), processService = inject(ProcessService)) => {
    return actions$.pipe(
      ofType(runProcessActions.loadPeriodDate),
      exhaustMap(() =>
        processService.periodDate().pipe(
          map((payload) => runProcessActions.loadPeriodDateSuccess({ payload })),
          catchError((error) =>
            of(runProcessActions.loadPeriodDateError({ payload: mapDialogErrors.get(error.code) ?? 'Error desconocido' }))
          )
        )
      )
    );
  },
  { functional: true }
);

export const getPeriodDateEffects = createEffect(
  (actions$ = inject(Actions), processService = inject(ProcessService)) => {
    return actions$.pipe(
      ofType(runProcessActions.getPeriodDate),
      exhaustMap(() =>
        processService.periodDate().pipe(
          map((payload) => runProcessActions.getPeriodDateSuccess({ payload })),
          catchError((error) =>
            of(runProcessActions.getPeriodDateError({ payload: mapDialogErrors.get(error.code) ?? 'Error desconocido' }))
          )
        )
      )
    );
  },
  { functional: true }
);

export const getProcessLogsAfterDateEffects = createEffect(
  (actions$ = inject(Actions), processRunningService = inject(ProcessRunningService),
          store= inject(Store)) => {
    return actions$.pipe(
      ofType(runProcessActions.getPeriodDateSuccess),
      concatLatestFrom(action => {
        return [
          store.select(selectRunProcessDate)
        ]
      }),
      filter(([action, processDate]) =>
        !!processDate.month && !!processDate.year),
      switchMap(([action, processDate]) =>
        processRunningService.get({year: processDate.year, month: processDate.month}).pipe(
            map((payload) => runProcessActions.getProcessLogsSuccess({ payload })),
            catchError((error) =>
              of(runProcessActions.getProcessLogsError({ payload: mapDialogErrors.get(error.code) ?? 'Error desconocido' }))
            )
          )
      )
    );
  },
  { functional: true }
);

export const getProcessLogsDateEffects = createEffect(
  (actions$ = inject(Actions), processRunningService = inject(ProcessRunningService),
          store= inject(Store)) => {
    return actions$.pipe(
      ofType(runProcessActions.getProcessLogs),
      concatLatestFrom(action => {
        return [
          store.select(selectRunProcessDate)
        ]
      }),
      switchMap(([action, processDate]) =>
        processRunningService.get({year: processDate.year, month: processDate.month}).pipe(
            map((payload) => runProcessActions.getProcessLogsSuccess({ payload })),
            catchError((error) =>
              of(runProcessActions.getProcessLogsError({ payload: mapDialogErrors.get(error.code) ?? 'Error desconocido' }))
            )
          )
      )
    );
  },
  { functional: true }
);

export const closePeriodEffects = createEffect(
  (actions$ = inject(Actions), processService = inject(ProcessService),
          store= inject(Store)) => {
    return actions$.pipe(
      ofType(runProcessActions.closePeriod),
      concatLatestFrom(action => {
        return [
          store.select(selectRunProcessDate)
        ]
      }),
      switchMap(([action, processDate]) =>
        processService.closePeriod({year: processDate.year, month: processDate.month}).pipe(
            map((payload) => runProcessActions.closePeriodSuccess({ payload })),
            catchError((error) =>
              of(runProcessActions.closePeriodError({ payload: mapDialogErrors.get(error.code) ?? 'Error desconocido' }))
            )
          )
      )
    );
  },
  { functional: true }
);

export const closePeriodAlertEffects = createEffect(  (actions$ = inject(Actions), alert = inject(AlertService)) => {
    return actions$.pipe(
      ofType(runProcessActions.closePeriodSuccess),
      map((payload) => {
          alert.showAlert({
              type: 'check_circle',
              text: 'El período ha sido cerrado con éxito',
              verticalPosition: 'bottom',
              horizontalPosition: 'right'
            });
          return runProcessActions.noop()
      }),
      catchError((error) =>
        of(runProcessActions.closePeriodError({ payload: mapDialogErrors.get(error.code) ?? 'Error desconocido' }))
      )
    );
  },
  { functional: true }
);

export const closePeriodErrorAlertEffects = createEffect(  (actions$ = inject(Actions), alert = inject(AlertService)) => {
    return actions$.pipe(
      ofType(runProcessActions.closePeriodError),
      map((payload) => {
          alert.showModal(errorClosingIntervalMetadata);
          return runProcessActions.noop()
      }),
      catchError((error: { message: string }) =>
        of(runProcessActions.noop())
      )
    );
  },
  { functional: true }
);

export const getProcessPeriodSummaryEffects = createEffect(
  (actions$ = inject(Actions), processService = inject(ProcessService),
          store= inject(Store)) => {
    return actions$.pipe(
      ofType(runProcessActions.getPeriodDateSuccess),
      concatLatestFrom(action => {
        return [
          store.select(selectRunProcessDate)
        ]
      }),
      filter(([action, processDate]) =>
        !!processDate.month && !!processDate.year),
      switchMap(([action, processDate]) =>
        processService.periodSummary({year: processDate.year, month: processDate.month}).pipe(
            map((payload) => runProcessActions.getProcessPeriodSummarySuccess({ payload })),
            catchError((error) =>
              of(runProcessActions.getProcessPeriodSummaryError({ payload: mapDialogErrors.get(error.code) ?? 'Error desconocido' }))
            )
          )
      )
    );
  },
  { functional: true }
);

export const runProcessEffects = createEffect(
  (actions$ = inject(Actions), processService = inject(ProcessService),
        processStore = inject(ProcessStore), store= inject(Store)) => {
    return actions$.pipe(
      ofType(runProcessActions.runProcess),
      concatLatestFrom(action => {
        return [
          store.select(selectRunProcessDate)
        ]
      }),
      switchMap(async ([action, processDate]) => {
          const documentReference = await processStore.runProcess(processDate);
          return {
            documentReference,
            processDate
          }
      }),
      switchMap(({processDate, documentReference}) => {
        return processService.run({
          year: processDate.year,
          month: processDate.month,
          doc_id: documentReference.id
        }).pipe(
          map((payload) => runProcessActions.saveProcessSuccess({
            payload, documentReference: documentReference.path })),
          catchError((error) =>
            of(runProcessActions.runProcessError({ payload: mapDialogErrors.get(error.code) ?? 'Error desconocido' }))
          )
        )
      })
    );
  },
  { functional: true }
);

export const sendProcessEffects = createEffect(
  (actions$ = inject(Actions), processService = inject(ProcessService),
   store= inject(Store)) => {
    return actions$.pipe(
      ofType(runProcessActions.sendProcess),
      concatLatestFrom(action => {
        return [
          store.select(selectRunProcessDate)
        ]
      }),
      switchMap(([action, processDate]) =>
        processService.send({year: processDate.year, month: processDate.month}).pipe(
          map((payload) => runProcessActions.sendProcessSuccess({ payload })),
          catchError((error) =>
            of(runProcessActions.sendProcessError({ payload: mapDialogErrors.get(error.code) ?? 'Error desconocido' }))
          )
        )
      )
    );
  },
  { functional: true }
);

export const sendProcessSuccessAlertEffects = createEffect(  (actions$ = inject(Actions), alert = inject(AlertService)) => {
    return actions$.pipe(
      ofType(runProcessActions.sendProcessSuccess),
      map((payload) => {
        alert.showAlert({
          type: 'check_circle',
          text: 'El período se ha enviado a validar con éxito',
          verticalPosition: 'bottom',
          horizontalPosition: 'right',
          duration: 2000,
        });
        return runProcessActions.noop()
      }),
      catchError((error: { message: string }) =>
        of(runProcessActions.noop())
      )
    );
  },
  { functional: true }
);

export const sendProcessErrorAlertEffects = createEffect(  (actions$ = inject(Actions), alert = inject(AlertService)) => {
    return actions$.pipe(
      ofType(runProcessActions.sendProcessError),
      map((payload) => {
        alert.showModal(genericErrorMetadata);
        return runProcessActions.noop()
      }),
      catchError((error: { message: string }) =>
        of(runProcessActions.noop())
      )
    );
  },
  { functional: true }
);

export const saveProcessEffects = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(runProcessActions.saveProcessSuccess),
      map(({payload}) => {
        return runProcessActions.runProcessSuccess({payload})
      })
    );
  },
  { functional: true }
);

export const deleteDocumentEffects = createEffect(
  (actions$ = inject(Actions), store= inject(Store), processStore = inject(ProcessStore)) => {
    return actions$.pipe(
      ofType(runProcessActions.deleteDocument),
      switchMap(async ({payload}) => {
        return processStore.delDocument(payload);
      }),
      map((payload) => {
        return runProcessActions.clearDocumentReference();
      })
    );
  },
  { functional: true }
);

export const reloadLogsAfterDocumentClearEffects = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(runProcessActions.clearDocumentReference),
      map((payload) => {
        return runProcessActions.getProcessLogs();
      })
    );
  },
  { functional: true }
);

export const reloadCNotifyAfterClosePeriodEffects = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(runProcessActions.closePeriodSuccess),
      map((payload) => {
        return runProcessActions.getPeriodDate();
      })
    );
  },
  { functional: true }
);

export const reloadCNotifyAfterDocumentClearEffects = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(runProcessActions.clearDocumentReference),
      map((payload) => {
        return runProcessActions.getPeriodDate();
      })
    );
  },
  { functional: true }
);
// export const displayErrorAlert = createEffect(
//   () => {
//     return inject(Actions).pipe(
//       ofType(ActorsApiActions.actorsLoadedFailure),
//       tap(({ errorMsg }) => alert(errorMsg))
//     );
//   },
//   { functional: true, dispatch: false }
// );
