import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { switchMap } from 'rxjs';

import { AuthService } from '@services';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const clientId = localStorage.getItem('client-id') ?? '';

  return inject(AuthService).user.pipe(
    switchMap((user) => user.getIdToken()),
    switchMap((idToken) =>
      next(
        req.clone({
          setHeaders: { Authorization: `Bearer ${idToken}` },
          setParams: { 'client-id': clientId },
        })
      )
    )
  );
};
