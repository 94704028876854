import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { AlertType } from '@common/constants';
import { AlertService, AuthService } from '@services';

export const oobCodeGuard: CanActivateFn = async (next) => {
  const _alert = inject(AlertService);
  const _router = inject(Router);

  return inject(AuthService)
    .verifyPasswordReset(next.queryParams['oobCode'])
    .then(() => true)
    .catch(() => {
      _alert.showAlert({
        duration: 5000,
        type: AlertType.ERROR,
        text: 'El link ha expirado o no es válido',
      });

      return _router.createUrlTree(['auth/login']);
    });
};
