export const AlertType = {
  INFO: 'check_circle',
  WARNING: 'emergency_home',
  ERROR: 'error',
  CONFIRMATION: 'confirmation',
} as const;

export const RoleName = {
  ADMIN: 'ADMIN',
  HHRR: 'HHRR',
  SELLER: 'SELLER',
  VALIDATOR: 'SUPERVISOR',
  TECHNICIAN: 'TECHNICIAN',
} as const;

export const SignUpOrRecoveryMode = {
  SIGN_UP: 'SIGN_UP',
  RECOVERY: 'RECOVERY',
} as const;

export const AppRoutes = {
  GENERAL_PAYLOADS: 'general_payloads',
  COMISSION_TYPES: 'comission_types',
  RUN_PROCESS: 'run_process',
  GENERAL_INFORMATION: 'general_information',
  SELL_PAYLOADS: 'sell_payloads',
  SELL_PAYLOADS_EMPLOYEE: 'sell_payloads_employee',
  SELL_PAYLOADS_GOALS: 'sell_payloads_goals',
  GENERAL_LIQUIDATION: 'general_liquidation',
  GENERAL_LIQUIDATION_ADMIN: 'general_liquidation_admin',
  GENERAL_LIQUIDATION_BY_EMPLOYEE: 'general_liquidation_by_employee',
  GENERAL_LIQUIDATION_BY_EMPLOYEE_ADMIN: 'general_liquidation_by_employee_admin',
  ACCOUNT_CREATION: 'account_creation',
  ACCOUNT_MANAGEMENT: 'account_management',
} as const;

export const ProcessStatus = {
  RUNNING: 'En ejecucion',
  FINISHED: 'Terminado',
  FAILED: 'Terminado con observaciones'
} as const;

export const RolDescription: {[key:string]: string} = {
  ADMINISTRADOR: 'ADMINISTRADOR',
  VENDEDOR:'VENDEDOR',
  SUPERVISOR: 'SUPERVISOR',
  HHRR: 'RECURSOS HUMANOS',
  VALIDADOR: 'VALIDADOR',
  EMPLEADO: 'EMPLEADO',
  TECNICO: 'TÉCNICO'
} as const;

export const RoleDescr: {[key:string]: string} = {
  ADMIN: 'ADMINISTRADOR',
  HHRR: 'RECURSOS HUMANOS',
  SELLER: 'VENDEDOR',
  TECHNICIAN: 'TÉCNICO',
  SUPERVISOR: 'VALIDADOR',
} as const;

export type RolDescriptionType = keyof typeof RoleName
