import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {ThemePalette} from '@angular/material/core';
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from 'ngx-mask';

@Component({
  selector: 'rk-input',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  templateUrl: './rk-input.component.html',
  styleUrls: ['./rk-input.component.scss'],
  providers: [provideNgxMask()]
})
export class RkInputComponent implements OnInit {
  private readonly _errorMap: Record<string, string> = {
    required: 'Campo requerido',
    email: 'Email no válido',
    pattern: 'Contraseña no válida',
    isNotName: 'Nombre inválido',
    isNotRutCode: 'RUT inválido',
    isNotNumber: 'Valor inválido'
  };

  private readonly _handler: ProxyHandler<Record<string, string>> = {
    get(target, p: string, receiver) {
      if (!target[p]) return 'Campo no válido';
      return Reflect.get(target, p, receiver);
    },
  };

  private readonly _proxiedErrorMap = new Proxy(this._errorMap, this._handler);

  @Input() type = 'text';
  @Input() id!: string;
  @Input() control!: FormControl<unknown>;
  @Input() label: string | undefined;
  @Input() placeholder = '';
  @Input() hint: string | undefined;
  @Input() customWidth = '';
  @Input() readonly = false;
  @Input() color = 'primary' as ThemePalette;
  @Input() mask = '';
  @Input() hideRequiredMarker: boolean = true;

  @Input()
  set errorMap(map: Record<string, string>) {
    Object.assign(this._proxiedErrorMap, map);
  }

  forPassword = false;

  classes = 'w-full';

  ngOnInit(): void {
    this.forPassword = this.type === 'password';
    if(this.customWidth !== '') {
      this.classes = this.customWidth;
    }
  }

  togglePasswordVisibility() {
    this.type = this.type === 'password' ? 'text' : 'password';
  }

  get errorText() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._proxiedErrorMap[Object.keys(this.control.errors!).at(0)!];
  }
}
