import {inject} from '@angular/core';
import {catchError, concat, map, Observable, of, switchMap} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {genericErrorMetadata} from '@features/run-process/trigger-action/trigger.modal.metadata';
import {AlertService} from '@services';
import {sellPayloadsEmployeeActions} from '@features/sell-payloads-employee/store/sell-payloads-employee.actions';
import {SellPayloadsEmployeeService} from '../../../core/services/sell.payload.employee.service';
import {FullResponseError} from '@common/models';
import {UserService} from '../../../core/services/user.service';
import {mapDialogErrors} from "../../../core/maps/maps-error-msg";


export const loadEmployeesEffects = createEffect(
  (actions$ = inject(Actions), userService = inject(UserService)) => {
    return actions$.pipe(
      ofType(sellPayloadsEmployeeActions.loadEmployees),
      switchMap((action) =>
        userService.get().pipe(
          map((result) => sellPayloadsEmployeeActions.loadEmployeesSuccess({ payload: result })),
          catchError((error: FullResponseError) =>
            of(sellPayloadsEmployeeActions.loadEmployeesError({ payload: error.error }))
          )
        )
      )
    );
  },
  { functional: true }
);

export const uploadEmployeeEffects = createEffect(
  (actions$ = inject(Actions), sellPayloadsEmployeeService = inject(SellPayloadsEmployeeService), alert = inject(AlertService)) => {
    return actions$.pipe(
      ofType(sellPayloadsEmployeeActions.upload),
      switchMap(({payload}) =>
        sellPayloadsEmployeeService.loadSignUrlsEmployee(payload).pipe(
          map((payload) => sellPayloadsEmployeeActions.loadSignUrlsSuccess({payload})),
          catchError((error: any) => {
              const genericErrorMetadataNew = {...genericErrorMetadata, text: error.error.error.message};
              alert.showModal(genericErrorMetadataNew);
              return of(sellPayloadsEmployeeActions.uploadError({payload: mapDialogErrors.get(error.code) ?? 'Error desconocido',}))
            }
          )
        )
      )
    );
  },
  {functional: true}
);

export const onGetSignsUrlsEmployeeEffects = createEffect(
  (actions$ = inject(Actions), sellPayloadsEmployeeService = inject(SellPayloadsEmployeeService), alert = inject(AlertService)) => {
    return actions$.pipe(
      ofType(sellPayloadsEmployeeActions.loadSignUrlsSuccess),
      switchMap(action => {

        // llevarme esto para el servicio, alla subirlo todo y retornar respuesta ccon await
        // ver opcion de retornar para mostrar progreso
        const files = [...action.payload.files];
        const urls = [...action.payload.urls.urls];

        let pool: Array<Observable<any>> = [];
        files.forEach((file, index) => {
          pool.push(
            sellPayloadsEmployeeService.uploadToCloud({
              file,
              url: urls[index]
            })
          );
        });
        return concat(...pool)
      }),
      map((result) => {
        return sellPayloadsEmployeeActions.uploadSuccess({payload: result})
      }),
      catchError((error) => {
        const genericErrorMetadataNew = {...genericErrorMetadata, text: error.error.message};
        alert.showModal(genericErrorMetadataNew);
        return of(
          sellPayloadsEmployeeActions.uploadError({payload: error})
        );
      })
    );
  },
  {functional: true}
);
