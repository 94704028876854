import {createAction, props} from '@ngrx/store';
import {APIResponseForUser, SignUrlsEmployeeParams, SignUrlsWithFiles, UserDataAPI} from '@common/models';

export const loadSignUrls = createAction(  '[Sell Payloads Employee] loadSignUrls',  props<{ payload: any }>());
export const loadSignUrlsSuccess = createAction('[Sell Payloads Employee] loadSignUrls Success',  props<{ payload: SignUrlsWithFiles }>());
export const loadSignUrlsError = createAction('[Comisiones Generales] loadSignUrls Error',  props<{ payload: any }>());

export const upload = createAction(  '[Sell Payloads Employee] Upload',  props<{ payload: SignUrlsEmployeeParams }>());
export const uploadSuccess = createAction('[Sell Payloads Employee] Upload Success',  props<{ payload: any }>());
export const uploadError = createAction('[Sell Payloads Employee] Load Validationss Error',  props<{ payload: any }>());

export const loadEmployees = createAction(  '[Sell Payloads Employee] Load Employees');
export const loadEmployeesSuccess = createAction('[Sell Payloads Employee] Load Employees Success',  props<{ payload: APIResponseForUser<UserDataAPI> }>());
export const loadEmployeesError = createAction('[Sell Payloads Employee] Load Employees Error',  props<{ payload: any }>());

export const clearDocumentId = createAction(  '[Sell Payloads Employee] ClearDocumentId');


export const sellPayloadsEmployeeActions = {
  loadSignUrls,
  loadSignUrlsSuccess,
  loadSignUrlsError,

  upload,
  uploadSuccess,
  uploadError,

  loadEmployees,
  loadEmployeesSuccess,
  loadEmployeesError,

  clearDocumentId
};
