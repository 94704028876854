import { Subject, startWith } from 'rxjs';

export abstract class BaseMixin {
  private readonly _isBusy = new Subject<boolean>();
  isBusy$ = this._isBusy.pipe(startWith(false));

  protected _setBusy() {
    this._isBusy.next(true);
  }

  protected _setIdle() {
    this._isBusy.next(false);
  }
}
