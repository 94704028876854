import {Injectable, isDevMode} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';

import {catchError, Observable, of, shareReplay, throwError} from 'rxjs';
import {environment} from '@environments';
import {APIResponse, RunningProcess} from '@common/models';

const BASE_URL = environment.baseURL;

// const BASE_URL = 'https://dev-api.rentokil.seiza-ti.cl/api/v1/';
/**
 * Bitcora de procesos, procesos que se han ejecutado
 * */
@Injectable({
  providedIn: 'root'
})
export class ProcessRunningService {

  constructor(private _httpClient: HttpClient) {
  }

  _buildUrl(payload: {year: number; month: number; doc_id?: string;}): string {
    let query = `year=${payload.year}&month=${payload.month}`;
    if(payload?.doc_id) {
      query += `doc_id=${payload.doc_id}`;
    }
    return query;
  }

  get(payload: {year: number; month: number; doc_id?: string;}): Observable<APIResponse<RunningProcess>> {
    return this._httpClient.get<APIResponse<any>>(`${BASE_URL}process/logs?${this._buildUrl(payload)}`)
      .pipe(
        shareReplay(),
        // catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    let text = '';
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      text = error.message;
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      text = `Backend returned code ${error.status}, body was: ${error.message}`;
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    if (!isDevMode()) {
      text = 'Something bad happened; please try again later.'
    }
    // return throwError(() => new Error(text));
    return of(null);
  }
}
