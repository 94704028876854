import {Injectable, isDevMode} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';

import {catchError, Observable, of, shareReplay, throwError} from 'rxjs';
import {environment} from '@environments';
import {APIResponse, APIResponseForUser, APIResponsePeriodPending, ProcessSummary} from '@common/models';

const BASE_URL = environment.baseURL;

// const BASE_URL = 'https://dev-api.rentokil.seiza-ti.cl/api/v1/';

/**
 * Informacion relacionada al proceso en ejecucion o al ultimo ejecutado
 */
@Injectable({
  providedIn: 'root'
})
export class ProcessService {

  constructor(private _httpClient: HttpClient) {
  }

  _buildUrl(payload: {year: number; month: number; doc_id?: string;}): string {
    let query = `year=${payload.year}&month=${payload.month}`;
    if(payload?.doc_id) {
      query += `&doc_id=${payload.doc_id}`;
    }
    return query;
  }

  run(payload: any): Observable<APIResponse<any>> {
    return this._httpClient.post<APIResponse<any>>(`${BASE_URL}process?${this._buildUrl(payload)}`, payload)
      .pipe(
        shareReplay(),
        // catchError(this.handleError)
      );
  }

  periodDate(): Observable<APIResponsePeriodPending> {
    return this._httpClient.get<APIResponse<any>>(`${BASE_URL}process/period/pending`)
      .pipe(
        shareReplay(),
        catchError(this.handleError)
      );
  }

  periodSummary(payload: {month: number; year: number}): Observable<APIResponseForUser<ProcessSummary>> {
    return this._httpClient.get<APIResponseForUser<ProcessSummary>>(`${BASE_URL}process/period/summary?${this._buildUrl(payload)}`)
      .pipe(
        shareReplay(),
        // catchError(this.handleError)
      );
  }

  send(payload: any): Observable<APIResponse<any>> {
    return this._httpClient.post<APIResponse<any>>(`${BASE_URL}process/period/validation-due?${this._buildUrl(payload)}`, payload)
      .pipe(
        shareReplay(),
        // catchError(this.handleError)
      );
  }

  closePeriod(payload: {month: number; year: number;}): Observable<APIResponse<any>> {
    return this._httpClient.post<APIResponse<any>>(`${BASE_URL}process/period/close?${this._buildUrl(payload)}`, payload)
      .pipe(
        shareReplay(),
        // catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    let text = '';
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      text = error.message;
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      text = `Backend returned code ${error.status}, body was: ${error.message}`;
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    if (!isDevMode()) {
      text = 'Something bad happened; please try again later.'
    }
    // return throwError(() => new Error(text));
    return of(null as any);
  }
}
