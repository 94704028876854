import { createAction, props } from '@ngrx/store';
import {
  APIResponseSettlements,
  APIResponseSettlementsFilter,
  APIResponseSummary,
  Comission,
  SettlementFilter
} from '@common/models';

export const loadValidations = createAction(  '[Comisiones Generales] Load Validations');
export const loadValidationsSuccess = createAction('[Comisiones Generales] Load Validationss Success',  props<{ payload: APIResponseSummary<Comission> }>());
export const loadValidationsError = createAction('[Comisiones Generales] Load Validationss Error',  props<{ payload: any }>());

export const loadValidationsSeller = createAction(  '[Comisiones Generales] Load Validations Seller',  props<{ payload: SettlementFilter | {} } >());
export const loadValidationsSellerSuccess = createAction('[Comisiones Generales] Load Validations Seller Success',  props<{ payload: APIResponseSettlements }>());
export const loadValidationsSellerError = createAction('[Comisiones Generales] Load Validations Seller Error',  props<{ payload: any }>());

export const loadValidationsFilter = createAction(  '[Comisiones Generales] Load Validations Filter');
export const loadValidationsFilterSuccess = createAction('[Comisiones Generales] Load Validations Filter Success',  props<{ payload: APIResponseSettlementsFilter }>());
export const loadValidationsFilterError = createAction('[Comisiones Generales] Load Validations Seller Error',  props<{ payload: any }>());

export const loadValidationsSellerPaginate = createAction('[Comisiones Generales] Load Validations Seller Paginate',  props<{ payload: number }>());

export const generalInformationActions = {
  loadValidations,
  loadValidationsSuccess,
  loadValidationsError,

  loadValidationsSeller,
  loadValidationsSellerSuccess,
  loadValidationsSellerError,

  loadValidationsFilter,
  loadValidationsFilterSuccess,
  loadValidationsFilterError,

  loadValidationsSellerPaginate
};
