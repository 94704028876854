import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { loggedIn } from '@angular/fire/auth-guard';
import { map } from 'rxjs';

import { AuthPipeGenerator } from '@common/types';
import { AuthService } from '@services';

export const authGuard: CanActivateFn = (next, state) => {
  const _auth = inject(AuthService);
  const _router = inject(Router);

  const authPipeFactory = (next.data['authGuardPipe'] as AuthPipeGenerator) || (() => loggedIn);

  return _auth.user.pipe(
    authPipeFactory(next, state),
    map((can) => {
      if (typeof can === 'boolean') {
        return can;
      } else if (Array.isArray(can)) {
        return _router.createUrlTree(can);
      } else {
        return _router.parseUrl(can);
      }
    })
  );
};

export const canActivate = (pipe: AuthPipeGenerator) => ({
  canActivate: [authGuard],
  data: { authGuardPipe: pipe },
});
