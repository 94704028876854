<div *ngIf="{ isBusy: isBusy$ | async } as ctx">
  <h1 class="mb-10 text-center text-[28px]/[32px] font-bold">
    ¡Bienvenido! Ingresa a la plataforma
  </h1>
  <form [formGroup]="signInForm" (ngSubmit)="signIn()">
    <rk-input
      [type]="'email'"
      [id]="'sign-in-email'"
      [control]="signInForm.controls.email"
      [label]="'Correo electrónico'"
      [placeholder]="'usuario@rentokil.cl'"
    ></rk-input>
    <rk-input
      [type]="'password'"
      [id]="'sign-in-password'"
      [control]="signInForm.controls.password"
      [label]="'Contraseña'"
    ></rk-input>
    <rk-button
      [type]="'submit'"
      [label]="'Continuar'"
      [extended]="true"
      [disabled]="!signInForm.valid"
      [loading]="isBusy$"
    ></rk-button>
    <a
      [routerLink]="['/auth/recovery-password']"
      class="mt-5 block cursor-pointer text-center text-french-blue"
      [ngClass]="{ 'pointer-events-none': ctx.isBusy }"
    >
      Solicita o recupera tu clave de acceso
    </a>
    <a
      [routerLink]="['/auth/sign-up']"
      class="mt-5 block cursor-pointer text-center"
      [ngClass]="{ 'pointer-events-none': ctx.isBusy }"
      >Crear cuenta</a
    >
  </form>
</div>
