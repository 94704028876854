import {Injectable, isDevMode} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';

import {catchError, Observable, of, shareReplay, tap, throwError} from 'rxjs';
import {environment} from '@environments';
import {
  APIResponseForLines,
  APIResponseForUser,
  APIResponseSettlementsAvailable,
  UserData,
  UserDataAPI,
  UserProfile
} from '@common/models';
import {AccountDataTO} from './data.service';

const BASE_URL = environment.baseURL;

// const BASE_URL = 'https://dev-api.rentokil.seiza-ti.cl/api/v1/';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private _httpClient: HttpClient) {
  }

  addAccount(accountData: AccountDataTO): Observable<APIResponseForUser<UserData>> {
    return this._httpClient.post<APIResponseForUser<UserData>>(`${BASE_URL}employee`, accountData)
      .pipe(
        shareReplay(),
        catchError(this.handleError)
      );
  }

  get(): Observable<APIResponseForUser<UserDataAPI>> {
    return this._httpClient.get<APIResponseForUser<UserDataAPI>>(`${BASE_URL}employee`).pipe(
      catchError(this.handleError)
    );
  }

  getLines(): Observable<APIResponseForLines> {
    return this._httpClient.get<APIResponseForLines>(`${BASE_URL}data/sales-line`).pipe(
      catchError(this.handleError)
    );
  }

  getById(id:string): Observable<APIResponseForUser<UserData>> {
    return this._httpClient.get<APIResponseForUser<UserData>>(`${BASE_URL}employee/${id}`).pipe(
      catchError(this.handleError)
    );
  }

  getByIdProfile(id:string): Observable<APIResponseForUser<UserProfile>> {
    return this._httpClient.get<APIResponseForUser<UserProfile>>(`${BASE_URL}employee/${id}`).pipe(
      catchError(this.handleError)
    );
  }

  getByName (name:string): Observable<APIResponseForUser<UserDataAPI>> {
    let url = new URL('employee',BASE_URL);
    url.searchParams.append('full_name', encodeURI(name));
    return this._httpClient.get<APIResponseForUser<UserDataAPI>>(url.toString()).pipe(
      catchError(this.handleError)
    );
  }

  disable (id:string): Observable<APIResponseForUser<UserData>> {
    return this.patchService(`${BASE_URL}employee/${id}`, {disabled: true});
  }

  enable (id:string): Observable<APIResponseForUser<UserData>> {
    return this.patchService(`${BASE_URL}employee/${id}`, {disabled: false});
  }


  update (id:string, accountData: AccountDataTO): Observable<APIResponseForUser<UserData>> {
    return this.patchService(`${BASE_URL}employee/${id}`, accountData);
  }

  private patchService (url:string, userData:any):  Observable<APIResponseForUser<UserData>> {
    return this._httpClient.patch<APIResponseForUser<UserData>>(url,userData).pipe(
          catchError(this.handleError));
  }

  getSellerTypeCommissionSummaryById(id: string, year: number, month: number): Observable<any> {
    return this._httpClient.get<any>(`${BASE_URL}settlement/summary/by-employee/${id}?year=${year}&month=${month}`);
  }

  getSettlementsAvailablesById(id: string): Observable<APIResponseSettlementsAvailable> {
    return this._httpClient.get<any>(`${BASE_URL}settlement/list/by-employee/${id}`).pipe(
      catchError((error) => of({data: [], success: true, error: null}))
    );
  }

  getSettlementsById(payload: {id: string, year: number, month: number; page_number?: number; page_size?: number; _type?: string}): Observable<any> {
    const {id, year, month} = payload;
    let query  = `${id}?year=${year}&month=${month}`;

    if(payload.page_number) {
      query += `&page_number=${payload.page_number}`;
    }
    if(payload.page_size) {
      query += `&page_size=${payload.page_size}`;
    }
    if(payload._type) {
      query += `&_type=${payload._type}`;
    }
    return this._httpClient.get<any>(`${BASE_URL}settlement/by-employee/${query}`);
  }

  private handleError(error: HttpErrorResponse) {
    let text = '';
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      text = error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      text = `Backend returned code ${error.status}, body was: ${error.message}`;
    }
    if (!isDevMode()) {
      text = 'Something bad happened; please try again later.'
    }
    return throwError(() => new Error(text));
  }
}
