import {createAction, props} from '@ngrx/store';
import {APIPeriodNextResponse, SignUrlsParams, SignUrlsWithFiles} from '@common/models';

export const loadSignUrls = createAction(  '[Sell Payloads] loadSignUrls',  props<{ payload: any }>());
export const loadSignUrlsSuccess = createAction('[Sell Payloads] loadSignUrls Success',  props<{ payload: SignUrlsWithFiles }>());
export const loadSignUrlsError = createAction('[Comisiones Generales] loadSignUrls Error',  props<{ payload: any }>());

export const upload = createAction(  '[Sell Payloads] Upload',  props<{ payload: SignUrlsParams }>());
export const uploadSuccess = createAction('[Sell Payloads] Upload Success',  props<{ payload: any }>());
export const uploadError = createAction('[Sell Payloads] Upload Error',  props<{ payload: any }>());

export const periodNext = createAction(  '[Sell Payloads] Period Next');
export const periodNextSuccess = createAction('[Sell Payloads] Period Next Success',  props<{ payload: APIPeriodNextResponse }>());
export const periodNextError = createAction('[Sell Payloads] Period Next Error',  props<{ payload: any }>());

export const loadSellNotes = createAction(  '[Sell Payloads ] Load Sell Notes',  props<{ month: number; year: number; }>());
export const loadSellNotesSuccess = createAction('[Sell Payloads ] Load Sell Notes Success',  props<{ payload: any }>());
export const loadSellNotesError = createAction('[Sell Payloads ] Load Sell Notes Error',  props<{ payload: any }>());

export const clearDocumentId = createAction(  '[Sell Payloads] ClearDocumentId');

export const loadSellNotesErrors = createAction(  '[Sell Payloads ] Load Sell Notes Errors');
export const loadSellNotesErrorsSuccess = createAction('[Sell Payloads ] Load Sell Notes Success Errors',  props<{ payload: any }>());
export const loadSellNotesErrorsError = createAction('[Sell Payloads ] Load Sell Notes Error Errors',  props<{ payload: any }>());

export const sellPayloadsActions = {
  loadSignUrls,
  loadSignUrlsSuccess,
  loadSignUrlsError,

  upload,
  uploadSuccess,
  uploadError,

  periodNext,
  periodNextSuccess,
  periodNextError,

  loadSellNotes,
  loadSellNotesSuccess,
  loadSellNotesError,

  clearDocumentId,

  loadSellNotesErrors,
  loadSellNotesErrorsSuccess,
  loadSellNotesErrorsError,
};
