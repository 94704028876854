<h1 class="mb-4 text-center text-[28px]/[32px] font-bold">Ingresa tu nueva contraseña</h1>
<p class="mb-6 text-center text-lg font-medium">Escribe una contraseña</p>
<p class="mb-6 text-sm">Debe contener 8 caracteres como mínimo, una letra mayúscula y un número</p>
<form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
  <rk-input
    [type]="'password'"
    [id]="'reset-password-password'"
    [control]="resetPasswordForm.controls.password"
    [label]="'Contraseña'"
  ></rk-input>
  <rk-input
    [type]="'password'"
    [id]="'reset-password-confirm_password'"
    [control]="resetPasswordForm.controls.confirmPassword"
    [label]="'Confirmar contraseña'"
    [errorMap]="{ passwordMismatch: 'Las contraseñas no coinciden' }"
  ></rk-input>
  <rk-button
    [type]="'submit'"
    [label]="'Continuar'"
    [extended]="true"
    [disabled]="!resetPasswordForm.valid"
    [loading]="isBusy$"
  ></rk-button>
</form>
