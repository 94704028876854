import {createAction, props} from '@ngrx/store';
import {APIResponseForUser, UserProfile} from '@common/models';

export const loadEmployeeInformation = createAction(  '[Employee Information] Load Employeee Information',  props<{ id: string; }>());
export const loadEmployeeInformationSuccess = createAction('[Employee Information] Load Employeee Informations Success',  props<{ payload: APIResponseForUser<UserProfile> }>());
export const loadEmployeeInformationError = createAction('[Employee Information] Load Employeee Informations Error',  props<{ payload: any }>());

export const employeeInformationActions = {
  loadEmployeeInformation,
  loadEmployeeInformationSuccess,
  loadEmployeeInformationError
};
