import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EMPTY, Observable } from 'rxjs';

@Component({
  selector: 'rk-button',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule],
  templateUrl: './rk-button.component.html',
  styleUrls: ['./rk-button.component.scss'],
})
export class RkButtonComponent {
  @Input() color: 'primary' | 'accent' | 'warn' = 'primary';
  @Input() type = 'button';
  @Input() label = 'Button';
  @Input() stroked = false;
  @Input() extended = false;
  @Input() disabled = false;
  @Input() extraClasses = '';
  defaultClass = '!min-w-[160px] font-inter';

  @Input() loading: Observable<boolean> = EMPTY;

  @Output() doClick = new EventEmitter<void>();

  onClick() {
    this.doClick.emit();
  }
}
