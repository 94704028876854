import {inject} from '@angular/core';
import {catchError, concat, map, Observable, of, switchMap} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {genericErrorMetadata} from '@features/run-process/trigger-action/trigger.modal.metadata';
import {AlertService} from '@services';
import {SellPayloadsGoalsService} from '../../../core/services/sell.payload.goals.service';
import {sellPayloadsGoalsActions} from '@features/sell-payloads-goals/store/sell-payloads-goals.actions';
import {FullResponseError} from '../../../common/models';
import {UserService} from '../../../core/services/user.service';
import {mapDialogErrors} from "../../../core/maps/maps-error-msg";

export const loadGoalsEmployeesEffects = createEffect(
  (actions$ = inject(Actions), userService = inject(UserService)) => {
    return actions$.pipe(
      ofType(sellPayloadsGoalsActions.loadGoals),
      switchMap((action) =>
        userService.get().pipe(
          map((result) => sellPayloadsGoalsActions.loadGoalsSuccess({ payload: result })),
          catchError((error: FullResponseError) =>
            of(sellPayloadsGoalsActions.loadGoalsError({ payload: error.error }))
          )
        )
      )
    );
  },
  { functional: true }
);

export const uploadGoalsEffects = createEffect(
  (actions$ = inject(Actions), sellPayloadsGoalsService = inject(SellPayloadsGoalsService),
   alert = inject(AlertService)) => {
    return actions$.pipe(
      ofType(sellPayloadsGoalsActions.upload),
      switchMap(({payload}) =>
        sellPayloadsGoalsService.loadSignUrlsGoals(payload).pipe(
          map((payload) => sellPayloadsGoalsActions.loadSignUrlsSuccess({payload})),
          catchError((error: any) => {
              const genericErrorMetadataNew = {...genericErrorMetadata, text: mapDialogErrors.get(error.error.error.code) ?? 'Error desconocido'};
              alert.showModal(genericErrorMetadataNew);
              return of(sellPayloadsGoalsActions.uploadError({payload: mapDialogErrors.get(error.code) ?? 'Error desconocido',}))
            }
          )
        )
      )
    );
  },
  {functional: true}
);

export const onGetSignsUrlsGoalsEffects = createEffect(
  (actions$ = inject(Actions), sellPayloadsGoalsService = inject(SellPayloadsGoalsService),
   alert = inject(AlertService)) => {
    return actions$.pipe(
      ofType(sellPayloadsGoalsActions.loadSignUrlsSuccess),
      switchMap(action => {

        // llevarme esto para el servicio, alla subirlo todo y retornar respuesta ccon await
        // ver opcion de retornar para mostrar progreso
        const files = [...action.payload.files];
        const urls = [...action.payload.urls.urls];
        const year: number = action.payload.year;

        let pool: Array<Observable<any>> = [];
        files.forEach((file, index) => {
          pool.push(
            sellPayloadsGoalsService.uploadToCloud({
              file,
              year,
              url: urls[index]
            })
          );
        });
        return concat(...pool)
      }),
      map((result) => {
        return sellPayloadsGoalsActions.uploadSuccess({payload: result})
      }),
      catchError((error) => {
        const genericErrorMetadataNew = {...genericErrorMetadata, text: mapDialogErrors.get(error.error.code) ?? 'Error desconocido'};
        alert.showModal(genericErrorMetadataNew);
        return of(
          sellPayloadsGoalsActions.uploadError({payload: error})
        );
      })
    );
  },
  {functional: true}
);
