import {inject} from '@angular/core';
import {catchError, map, of, switchMap} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {employeeInformationActions} from '@features/general-liquidation-by-employee/store/employee-information.actions';
import {UserService} from '../../../core/services/user.service';
import {
  sellerTypeCommisionSummaryActions
} from '@shared/components/seller-type-commission-summary/seller-type-comission-summary.actions';
import {
  noAdminLiquidationsActions
} from '@features/general-liquidation-by-employee/no-admin-liquidations/noAdminLiquidations.actions';
import {mapDialogErrors} from "../../../core/maps/maps-error-msg";


export const loadEmployeeInformationEffects = createEffect(
  (actions$ = inject(Actions), userService = inject(UserService)) => {
    return actions$.pipe(
      ofType(employeeInformationActions.loadEmployeeInformation),
      switchMap(({id}) =>
        userService.getByIdProfile(id).pipe(
            map((payload) => employeeInformationActions.loadEmployeeInformationSuccess({ payload })),
            catchError((error) =>
              of(employeeInformationActions.loadEmployeeInformationError({ payload: mapDialogErrors.get(error.code) ?? 'Error desconocido' }))
            )
          )
      )
    );
  },
  { functional: true }
);

export const loadSellerTypeCommissionSummaryEffects = createEffect(
  (actions$ = inject(Actions), userService = inject(UserService)) => {
    return actions$.pipe(
      ofType(sellerTypeCommisionSummaryActions.loadSellerTypeCommissionSummary,
        noAdminLiquidationsActions.applyDateFilter),
      switchMap((params) =>
        userService.getSellerTypeCommissionSummaryById(params.id, params.year, params.month).pipe(
            map((payload) => sellerTypeCommisionSummaryActions.loadSellerTypeCommissionSummarySuccess({ payload })),
            catchError((error) =>
              of(sellerTypeCommisionSummaryActions.loadSellerTypeCommissionSummaryError({ payload: mapDialogErrors.get(error.code) ?? 'Error desconocido' }))
            )
          )
      )
    );
  },
  { functional: true }
);

export const loadSettlementsEffects = createEffect(
  (actions$ = inject(Actions), userService = inject(UserService)) => {
    return actions$.pipe(
      ofType(sellerTypeCommisionSummaryActions.loadSettlements,
        noAdminLiquidationsActions.applyDateFilter),
      switchMap((params) =>
        userService.getSettlementsById(params).pipe(
            map((payload) => sellerTypeCommisionSummaryActions.loadSettlementsSuccess({ payload })),
            catchError((error) =>
              of(sellerTypeCommisionSummaryActions.loadSettlementsError({ payload: mapDialogErrors.get(error.code) ?? 'Error desconocido' }))
            )
          )
      )
    );
  },
  { functional: true }
);

export const loadSettlementsAvailableEffects = createEffect(
  (actions$ = inject(Actions), userService = inject(UserService)) => {
    return actions$.pipe(
      ofType(sellerTypeCommisionSummaryActions.loadSettlementsAvailables),
      switchMap(({payload}) =>
        userService.getSettlementsAvailablesById(payload).pipe(
            map((payload) => sellerTypeCommisionSummaryActions.loadSettlementsAvailablesSuccess({ payload })),
            catchError((error) =>
              of(sellerTypeCommisionSummaryActions.loadSettlementsAvailablesError({ payload: mapDialogErrors.get(error.code) ?? 'Error desconocido' }))
            )
          )
      )
    );
  },
  { functional: true }
);

// export const displayErrorAlert = createEffect(
//   () => {
//     return inject(Actions).pipe(
//       ofType(ActorsApiActions.actorsLoadedFailure),
//       tap(({ errorMsg }) => alert(errorMsg))
//     );
//   },
//   { functional: true, dispatch: false }
// );
