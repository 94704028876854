import {Injectable, isDevMode} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';

import {catchError, Observable, of, shareReplay, tap, throwError} from 'rxjs';

import {
  APIResponseSettlements,
  APIResponseSettlementsFilter,
  APIResponseSummary,
  Comission,
  SettlementFilter
} from '@common/models';
import {environment} from '@environments';

const BASE_URL = environment.baseURL;
// const BASE_URL = 'https://dev-api.rentokil.seiza-ti.cl/api/v1/';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor(private _httpClient: HttpClient) { }

  _buildUrl(payload: SettlementFilter): string {
    let query = ``;
    return query;
  }

  get(): Observable<APIResponseSummary<Comission>> {
    return this._httpClient.get<APIResponseSummary<Comission>>(`${BASE_URL}settlement/summary`)
      .pipe(        shareReplay()
      );
  }

  getFilterData(): Observable<APIResponseSettlementsFilter> {
    return this._httpClient.get<APIResponseSettlementsFilter>(`${BASE_URL}settlement/filter-data`).pipe(
      shareReplay()
    );
  }

  sendValidations(paylod: { employee_id: string; validated: boolean; }[]): Observable<any> {
    return this._httpClient.patch<any>(`${BASE_URL}settlement/summary`, paylod).pipe(
      shareReplay()
    );
  }

  getBySellers(payload: SettlementFilter): Observable<APIResponseSettlements> {
    let query = ``;
    if(payload.company_rut) {
      query += `&company_rut=${payload.company_rut}`
    }
    if(payload.employee_rut) {
      query += `&employee_rut=${payload.employee_rut}`
    }
    if(payload.sale_type) {
      query += `&sale_type=${payload.sale_type}`
    }
    if(payload.sales_line) {
      query += `&sales_line=${payload.sales_line}`
    }
    if(payload.start_date) {
      query += `&start_date=${new Date(payload.start_date).toISOString()}`
    }
    if(payload.end_date) {
      query += `&end_date=${new Date(payload.end_date).toISOString()}`
    }
    if(payload.page_number) {
      query += `&page_number=${payload.page_number}`
    }
    if(payload.page_size) {
      query += `&page_size=${payload.page_size}`
    }
    if(payload._type) {
      query += `&_type=${payload._type}`
    }
    return this._httpClient.get<APIResponseSettlements>(`${BASE_URL}settlement?${query}`).pipe(
      shareReplay()
    );
  }

}
