import {inject} from '@angular/core';
import {catchError, concat, map, Observable, of, switchMap} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {sellPayloadsActions} from '@features/sell-payloads/store/sell-payloads.actions';
import {SellPayloadsService} from '../../../core/services/sell.payload.service';
import {genericErrorMetadata} from '@features/run-process/trigger-action/trigger.modal.metadata';
import {AlertService} from '@services';
import {FullResponseError} from '@common/models';
import {mapDialogErrors} from "../../../core/maps/maps-error-msg";


export const loadSellNotesErrorEffects = createEffect(
  (actions$ = inject(Actions), sellPayloadsService = inject(SellPayloadsService)) => {
    return actions$.pipe(
      ofType(sellPayloadsActions.loadSellNotesErrors),
      switchMap((action) =>
        sellPayloadsService.loadSaleNotesErrors().pipe(
          map((result) => sellPayloadsActions.loadSellNotesErrorsSuccess({ payload: result })),
          catchError((error: FullResponseError) =>
            of(sellPayloadsActions.loadSellNotesErrorsError({ payload: error.error }))
          )
        )
      )
    );
  },
  { functional: true }
);
export const loadSellNotesEffects = createEffect(
  (actions$ = inject(Actions), sellPayloadsService = inject(SellPayloadsService)) => {
    return actions$.pipe(
      ofType(sellPayloadsActions.loadSellNotes),
      switchMap((action) =>
        sellPayloadsService.loadSaleNotes({year: action.year, month: action.month}).pipe(
          map((result) => sellPayloadsActions.loadSellNotesSuccess({ payload: result })),
          catchError((error: FullResponseError) =>
            of(sellPayloadsActions.loadSellNotesError({ payload: error.error }))
          )
        )
      )
    );
  },
  { functional: true }
);

export const periodNextEffects = createEffect(
  (actions$ = inject(Actions), sellPayloadsService = inject(SellPayloadsService), alert = inject(AlertService)) => {
    return actions$.pipe(
      ofType(sellPayloadsActions.periodNext),
      switchMap(() =>
        sellPayloadsService.getPeriodNext().pipe(
          map((payload) => sellPayloadsActions.periodNextSuccess({payload})),
          catchError((error: any) => {
              const genericErrorMetadataNew = {...genericErrorMetadata, text: mapDialogErrors.get(error.error.error.code) ?? 'Error desconocido'};
              alert.showModal(genericErrorMetadataNew);
              return of(sellPayloadsActions.periodNextError({payload: mapDialogErrors.get(error.code) ?? 'Error desconocido'}))
            }
          )
        )
      )
    );
  },
  {functional: true}
);

export const uploadEffects = createEffect(
  (actions$ = inject(Actions), sellPayloadsService = inject(SellPayloadsService), alert = inject(AlertService)) => {
    return actions$.pipe(
      ofType(sellPayloadsActions.upload),
      switchMap(({payload}) =>
        sellPayloadsService.loadSignUrls(payload).pipe(
          map((payload) => sellPayloadsActions.loadSignUrlsSuccess({payload})),
          catchError((error: any) => {
              const genericErrorMetadataNew = {...genericErrorMetadata, text: mapDialogErrors.get(error.error.error.code) ?? 'Error desconocido'};
              alert.showModal(genericErrorMetadataNew);
              return of(sellPayloadsActions.uploadError({payload: mapDialogErrors.get(error.code) ?? 'Error desconocido',}))
            }
          )
        )
      )
    );
  },
  {functional: true}
);

export const onGetSignsUrlsEffects = createEffect(
  (actions$ = inject(Actions), sellPayloadsService = inject(SellPayloadsService), alert = inject(AlertService)) => {
    return actions$.pipe(
      ofType(sellPayloadsActions.loadSignUrlsSuccess),
      switchMap(action => {

        // llevarme esto para el servicio, alla subirlo todo y retornar respuesta ccon await
        // ver opcion de retornar para mostrar progreso
        const files = [...action.payload.files];
        const urls = [...action.payload.urls.urls];

        const picUrl = urls.pop();
        const picFile = files.shift();
        let pool: Array<Observable<any>> = [];
        files.forEach((file, index) => {
          pool.push(
            sellPayloadsService.uploadToCloud({
              file,
              url: urls[index]
            })
          );
        });
        pool.push(
          sellPayloadsService.uploadToCloud({
            url: picUrl as string,
            file: picFile as File
          })
        )
        return concat(...pool)
      }),
      map((result) => {
        return sellPayloadsActions.uploadSuccess({payload: result})
      }),
      catchError((error) => {
        const genericErrorMetadataNew = {...genericErrorMetadata, text: mapDialogErrors.get(error.error.code) ?? 'Error desconocido'};
        alert.showModal(genericErrorMetadataNew);
        return of(
          sellPayloadsActions.uploadError({payload: error})
        );
      })
    );
  },
  {functional: true}
);
