import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ProcessState, runProcessFeatureKey} from '@features/run-process/store/process.reducer';

export const selectRunProcessFeature = createFeatureSelector<ProcessState>(runProcessFeatureKey);

/********************------------------------ period/pending ------------------------********************/
// Si el periodo esta cerrado
export const selectRunProcessIsClosed = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => state.pending.payload?.data.closed
);

// Date information
export const selectRunProcessDate = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => ({
    year: state.pending.payload?.data.period.year as number,
    month: state.pending.payload?.data.month as number,
  })
);
export const selectRunProcessDateLoading = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => state.pending.loading
);
export const periodDate = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => state.pending.payload
);

// Data
export const selectRunProcessData = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => state.pending.payload?.data
);

// Error
export const selectRunProcessError = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => state.pending.payload?.error
);

// Loading state
export const selectRunProcessIsLoading = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => state.pending.loading
);
/********************------------------------end period/pending ------------------------********************/

/********************------------------------Start process/logs ------------------------********************/
// Data
export const selectRunProcessLogsData = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => state.logs.payload?.data
);

// Last Process
export const selectRunProcessLogsDataLast = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => state.logs.payload?.data[0]
);

// Last Process
export const selectRunProcessLogsOneValid = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return !!state.logs.payload?.data.find(process => process.status === 'FINISHED')
  }
);

// Error
export const selectRunProcessLogsError = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => state.logs.payload?.error
);

// Loading state
export const selectRunProcessLogsIsLoading = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => state.logs.loading
);
/********************------------------------end process/logs ------------------------********************/

/********************------------------------Start process-period/summary ------------------------********************/
// Data
export const selectRunProcessPeriodSummaryData = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => state.periodSummary.payload?.data
);

// Employees
export const selectRunProcessPeriodSummaryEmployees = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => state.periodSummary.payload?.data.employees
);

// Sales notes
export const selectRunProcessPeriodSummarySaleNotes = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => state.periodSummary.payload?.data.sale_notes
);

// Liquidations
export const selectRunProcessPeriodSummaryLiquidationsIsOk = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    if(!state.periodSummary.payload?.data.liquidations) {
      return false;
    }else {
      return state.periodSummary.payload?.data?.liquidations?.total > 0 &&
        state.periodSummary.payload?.data.liquidations?.pending.length === 0
    }
  }
);
export const selectRunProcessPeriodSummaryLiquidations = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => state.periodSummary.payload?.data?.liquidations
);

// Error
export const selectRunProcessPeriodSummaryError = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => state.periodSummary.payload?.error
);

// Loading state
export const selectRunProcessPeriodSummaryIsLoading = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => state.periodSummary.loading
);
/********************------------------------end process/logs ------------------------********************/
/********************------------------------ Firestore Information-------------------********************/
// Triggered Process
export const selectRunProcess = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.runProcess.payload?.data
  }
);
// Document Reference
export const selectRunProcessDocumentReference = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.runProcess.documentReference
  }
);
/********************------------------------ End Firestore Information-------------------********************/
/********************------------------------Comission Information-------------------********************/
// Data response Validations
export const selectValidationsData = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.validations.payload?.data
  }
);
export const selectValidationsError = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.validations.payload?.error
  }
);
export const selectValidationsIsLoading = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.validations.loading
  }
);
// Data response Validations Seller
export const selectValidationsSeller = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.validationsSeller.payload?.data
  }
);
// Total Validations Seller
export const selectValidationsSellerTotal = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.validationsSeller.payload?.data.count
  }
);
// Total Amount Validations Seller
export const selectValidationsSellerTotalAmount = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.validationsSeller.payload?.data.total
  }
);
// PageNumber Validations Seller
export const selectValidationsSellerPageNUmber = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.validationsSeller.pageNumber
  }
);
export const selectValidationsSellerError = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.validationsSeller.payload?.error
  }
);
export const selectValidationsSellerIsLoading = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.validationsSeller.loading
  }
);
// Full response Filter
export const selectValidationsFilters = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.validationsFilter.payload?.data
  }
);
export const selectValidationsFiltersError = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.validationsFilter.payload?.error
  }
);
export const selectValidationsFiltersIsLoading = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.validationsFilter.loading
  }
);

// ViewInformation for General Information (Comisiones Generales)
export const sliceValidations = (state: ProcessState) => state.validations;
export const sliceValidationsSeller = (state: ProcessState) => state.validationsSeller;
export const sliceValidationsFilter = (state: ProcessState) => state.validationsFilter;
export const selectGeneralInformation = createSelector(
  sliceValidations,
  sliceValidationsSeller,
  sliceValidationsFilter,
  (validations, validationsSeller, validationsFilter) => {
    return {validations, validationsSeller, validationsFilter}
  }
);

/********************------------------------Sellpayloads process/logs ------------------------********************/
export const selectSellPayloadsLoading = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.sellPayloads.isUploading
  }
)

export const selectSellPayloadsPeriodNext = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.sellPayloads.periodNext.payload
  }
)

export const selectSellPayloadsPeriodNextIsLoading = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.sellPayloads.periodNext.loading
  }
)

export const selectSellPayloadsErrorsIsLoading = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.sellPayloads.sellNotesErrors.loading
  }
)

export const selectSellPayloadsErrors = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.sellPayloads.sellNotesErrors.payload
  }
)

/********************------------------------SellpayloadsEmployee process/logs ------------------------********************/
export const selectSellPayloadsEmployeeLoading = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.sellPayloadsEmployee.isUploading
  }
)
export const selectSellPayloadsEmployeeProcessId = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.sellPayloadsEmployee.processId
  }
)
export const selectSellPayloadsEmployees = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.sellPayloadsEmployee.employees.payload
  }
)
export const selectSellPayloadsEmployeesLoading = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.sellPayloadsEmployee.employees.loading
  }
)

/********************------------------------EmployeeImformation employee/id ------------------------********************/
export const selectEmployeeInformationLoading = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.employeeInformation.loading
  }
)

export const selectEmployeeInformationPayload = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.employeeInformation.payload
  }
)

/********************------------------------EmployeeImformation Tabla Commission Summary ------------------------********************/
export const settlementsCommissionSummaryLoading = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.settlementSummaryByEmployee.loading
  }
)

export const settlementsCommissionSummary = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.settlementSummaryByEmployee.payload?.data.summary
  }
)

export const settlementsCommissionSummaryTotal = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.settlementSummaryByEmployee.payload?.data.commission_total
  }
)

export const settlementsCommissionPedTotal = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.settlementSummaryByEmployee.payload?.data.ped_total
  }
)

export const settlementsCommissionSalesTotal = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.settlementSummaryByEmployee.payload?.data.sales_total
  }
)

export const settlementsCommissionMonthlySell = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.settlementSummaryByEmployee.payload?.data.summary
      .map(commission => commission.value)
      .reduce((prev, curr) => prev + curr, 0)
  }
)

/********************------------------------EmployeeImformation Tabla Notas de Ventas ------------------------********************/
export const settlementsLoading = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.settlementByEmployee.loading
  }
)

export const settlementsList = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.settlementByEmployee.payload?.data.settlements
  }
)

export const settlementsTotal = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.settlementByEmployee.payload?.data.count
  }
)
/********************------------------------EmployeeImformation Settlements Available ------------------------********************/
export const settlementsAvailableLoading = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.settlementsAvailable.loading
  }
)

export const settlementsAvailableList = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.settlementsAvailable.payload?.data ?? []
  }
)

/********************------------------------Cargas General  Tabla Notas de Ventas ------------------------********************/
export const selectSellPayloadsProcessId = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.sellPayloads.processId
  }
)
export const sellPayloadsLoading = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.sellPayloads.sellNotes.loading
  }
)

export const sellPayloadsList = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.sellPayloads.sellNotes.payload
  }
)
/********************------------------------Cargas Metas  Tabla Notas de Ventas ------------------------********************/


export const selectSellPayloadsGoalsLoading = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.sellPayloadsGoals.isUploading
  }
)
export const selectSellPayloadsGoalsProcessId = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.sellPayloadsGoals.processId
  }
)
export const selectSellPayloadsEmployeesGoals = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.sellPayloadsGoals.employees.payload
  }
)
export const selectSellPayloadsEmployeesGoalsLoading = createSelector(
  selectRunProcessFeature,
  (state: ProcessState) => {
    return state.sellPayloadsGoals.employees.loading
  }
)

