import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, shareReplay, take} from 'rxjs';
import {ProcessService} from '../../core/services/process.service';
import {
  addDoc,
  collection,
  collectionData,
  deleteDoc,
  doc,
  docData,
  DocumentData,
  DocumentReference,
  Firestore,
} from '@angular/fire/firestore';
import {traceUntilFirst} from '@angular/fire/performance';
import {
  APIResponseForUser,
  APIResponsePeriodPending,
  ProcessSummary,
  RunningProcess,
  validLiquidations
} from '@common/models';
import {Store} from '@ngrx/store';
import {runProcessActions} from '@features/run-process/store/process.actions';
import {environment} from '@environments';

const RENTOKIL_COLLECTION = environment.calc_log;

/**
 * Informacion relacionada al proceso en ejecucion o al ultimo ejecutado
 */
@Injectable({
  providedIn: 'root'
})
export class ProcessStore {
  // Informacion referente al proceso en ejecucion guardada en Firestore
  private _process: BehaviorSubject<RunningProcess | DocumentData> = new BehaviorSubject(
    null as any
  );
  public readonly process: Observable<RunningProcess | DocumentData> = this._process
    .asObservable()
    .pipe(shareReplay());

  // Informacion sobre la fecha del periodo en curso
  private _periodDate: BehaviorSubject<APIResponsePeriodPending> = new BehaviorSubject(null as any);
  public readonly periodDate: Observable<APIResponsePeriodPending> = this._periodDate.asObservable();

  // Informacion sumario sobre el periodo en curso
  private _periodSummary: BehaviorSubject<APIResponseForUser<ProcessSummary>> = new BehaviorSubject(null as any);
  public readonly periodSummary: Observable<APIResponseForUser<ProcessSummary>> = this._periodSummary.asObservable();

  // Informacion sobre validaciones
  private _validations: BehaviorSubject<validLiquidations> = new BehaviorSubject(null as any);
  public readonly validations: Observable<validLiquidations> = this._validations.asObservable();

  private _firestore: Firestore = inject(Firestore);
  documentReference!: DocumentReference;
  ref!: DocumentReference;

  constructor(private _processService: ProcessService,
              private _store: Store) {  }

  get processData(): any {
    return this._process.getValue();
  }

  /**
   * Mandar a ejecutar el proceso, la respuesta no interesa pues se lee por Firestore
   */
  async runProcess(params: { month: number; year: number; }): Promise<{ id: string; path: string; }> {
    this.documentReference = await this.addDocument();

    this.handlerProcessState();
    return {id: this.documentReference.id, path: this.documentReference.path};
  }

  /**
   * Information about date and time
   */
  getPeriodDate(): Observable<APIResponsePeriodPending> {
    const response = this._processService.periodDate();
    response.subscribe((data) => {
      this._periodDate.next(data);
    });
    return response;
  }

  /**
   * Summary information
   */
  getPeriodSummary(payload: { month: number; year: number; }): Observable<APIResponseForUser<ProcessSummary>> {
    const response = this._processService.periodSummary(payload);
    response.subscribe((data: APIResponseForUser<ProcessSummary>) => {
      this._periodSummary.next(data);
      this._validations.next({
        liquidations: data.data.liquidations
      });
    });
    return response;
  }

  sendProcess(payload: { month: number; year: number; }): Observable<any> {
    const response = this._processService.send(payload).pipe(shareReplay());
    response.subscribe((process: any) => { });
    return response;
  }

  closePeriod(payload: { month: number; year: number; }): Observable<any> {
    const response = this._processService.closePeriod(payload).pipe(shareReplay());
    response.subscribe((process: any) => { });
    return response;
  }

  async addDocument(): Promise<DocumentReference> {
    const rentokilCalcLogCollection = collection(this._firestore, RENTOKIL_COLLECTION);
    return await addDoc(rentokilCalcLogCollection, {});
  }

  async delDocument(documentPath: string): Promise<void> {
    this.ref = doc(this._firestore, documentPath);
    return await deleteDoc(this.ref).then(() => {
    });
  }

  public getLastInfo() {
    const rentokilCalcLogCollection = collection(this._firestore, RENTOKIL_COLLECTION);
    collectionData(rentokilCalcLogCollection).pipe(
      take(1),
    )
      .subscribe((data) => {
        this._process.next(data[data.length - 1]);
      });
  }

  public handlerProcessState() {
    if (!this.ref) return;
    docData(this.ref)
      .pipe(
        // takeUntil(unSubscribeS),
        take(2),
        traceUntilFirst('firestore')
      )
      .subscribe((payload) => {
        console.warn(9, payload);
        if (Object.keys(payload).length === 0) return;
        // this._process.next(payload);
        this._store.dispatch(runProcessActions.runProcessSuccess(
          {payload}
        ))
      });
  }
}
