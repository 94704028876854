import {createAction, props} from '@ngrx/store';
import {APIResponseSettlements, APIResponseSettlementsAvailable, APISettlementSummaryByEmployee} from '@common/models';


/*
* Request information about current period
 endpoint: process/period/pending
* */
const loadSellerTypeCommissionSummary = createAction('[SellerTypeCommisionSummary] Load', props<{id: string; year: number; month: number;}>());
const loadSellerTypeCommissionSummarySuccess = createAction('[SellerTypeCommisionSummary] Success', props<{payload: APISettlementSummaryByEmployee}>());
const loadSellerTypeCommissionSummaryError = createAction('[SellerTypeCommisionSummary] Error', props<{payload: any}>());

const loadSettlements = createAction('[LoadSettlements] Load', props<{id: string, year: number, month: number; page_number?: number; page_size?: number; _type?: string}>());
const loadSettlementsSuccess = createAction('[LoadSettlements] Success', props<{payload: APIResponseSettlements}>());
const loadSettlementsError = createAction('[LoadSettlements] Error', props<{payload: any}>());

const loadSettlementsAvailables = createAction('[LoadSettlements Availables] Load', props<{payload: string;}>());
const loadSettlementsAvailablesSuccess = createAction('[LoadSettlements Availables] Success', props<{payload: APIResponseSettlementsAvailable}>());
const loadSettlementsAvailablesError = createAction('[LoadSettlements Availables] Error', props<{payload: any}>());


const noop = createAction('[SellerTypeCommisionSummary ->Noop]');

export const sellerTypeCommisionSummaryActions = {
  loadSellerTypeCommissionSummary,
  loadSellerTypeCommissionSummarySuccess,
  loadSellerTypeCommissionSummaryError,

  loadSettlements,
  loadSettlementsSuccess,
  loadSettlementsError,

  loadSettlementsAvailables,
  loadSettlementsAvailablesSuccess,
  loadSettlementsAvailablesError,

  noop
}
