import { ModalMetadata } from '@common/types';
import { AlertType as ModalType } from '@common/constants';

export { modalErrorMetadata } from '../sign-up-or-recovery/sign-up-or-recovery.modal-metadata';

export const modalSuccessMetadata: ModalMetadata = {
  type: ModalType.INFO,
  title: 'La contraseña fue creada con éxito',
  text: 'Ingresa a la plataforma con tu correo electrónico y contraseña.',
  buttons: [{ label: 'Cerrar' }],
};
