import {ModalMetadata} from '@common/types';
import {AlertType as ModalType} from '@common/constants';

export const detailsMetadata: ModalMetadata = {
  type: ModalType.INFO,
  title: 'Usuarios que no han realizado validaciones',
  text: 'Dirígete a tu bandeja de entrada y sigue las instrucciones para restablecer tu contraseña.',
  buttons: [{ label: 'Cerrar' }],
};

export const closeIntervalMetadata: ModalMetadata = {
  type: ModalType.INFO,
  title: 'El período en curso será cerrado y no podrás realizar más cambios',
  text: '¿Estás seguro de que quieres cerrar este período?',
  buttons: [
    {
      label: 'No, volver',
      extraClasses: '!bg-white !text-french-blue !border !border-french-blue !border-solid text-[14px] fw-medium',
      result: false
    },
    {
      label: 'Sí, cerrar',
      result: true
    }
  ],
  extraCss: 'confirmation'
};

export const errorClosingIntervalMetadata: ModalMetadata = {
  type: ModalType.ERROR,
  title: 'El período no puede ser cerrado, faltan liquidaciones por validar',
  text: '',
  buttons: [
    {
      label: 'Cancelar',
      result: 'cancel'
    }
  ]
};

export const infoWarningClosingIntervalMetadata: ModalMetadata = {
  type: ModalType.ERROR,
  title: 'Para cerrar el período en curso debes esperar a que se ejecute el proceso de cálculo y su estado pase a “Terminado”',
  text: '',
  buttons: [
    {
      label: 'Cancelar',
      result: 'cancel'
    }
  ]
};
