import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

import { AlertMetadata, ModalMetadata } from '@common/types';
import { SnackAlertComponent } from '@components/shared/dialogs/snack-alert/snack-alert.component';
import { ModalAlertComponent } from '@components/shared/dialogs/modal-alert/modal-alert.component';
import {ComponentType} from '@angular/cdk/overlay';
import DetailsMsgComponent from '@features/run-process/notify/details-msg.component';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private readonly _dialog = inject(MatDialog);
  private readonly _snackBar = inject(MatSnackBar);

  showCustomModal(customComponent: ComponentType<any>, metadata: ModalMetadata) {
    return this._dialog.open(DetailsMsgComponent, { data: metadata, disableClose: true });
  }
  showModal(metadata: ModalMetadata) {
    return this._dialog.open(ModalAlertComponent, { data: metadata, disableClose: true });
  }

  showAlert(metadata: AlertMetadata) {
    const { duration, type, text, verticalPosition, horizontalPosition } = metadata;

    return this._snackBar.openFromComponent(SnackAlertComponent, {
      duration: duration ? duration : 1500,
      panelClass: type,
      verticalPosition,
      horizontalPosition,
      data: {
        type,
        text,
      },
    });
  }
}
