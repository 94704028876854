import {createAction, props} from '@ngrx/store';
import {SignUrlsGoalsParams, SignUrlsWithFilesGoals} from '@common/models';

export const loadSignUrls = createAction(  '[Sell Payloads Goals] loadSignUrls',  props<{ payload: any }>());
export const loadSignUrlsSuccess = createAction('[Sell Payloads Goals] loadSignUrls Success',  props<{ payload: SignUrlsWithFilesGoals }>());
export const loadSignUrlsError = createAction('[Sell Payloads Goals] loadSignUrls Error',  props<{ payload: any }>());

export const upload = createAction(  '[Sell Payloads Goals] Upload',  props<{ payload: SignUrlsGoalsParams }>());
export const uploadSuccess = createAction('[Sell Payloads Goals] Upload Success',  props<{ payload: any }>());
export const uploadError = createAction('[Sell Payloads Goals] Load Validationss Error',  props<{ payload: any }>());

export const loadGoals = createAction(  '[Sell Payloads Goals] Load Sell ');
export const loadGoalsSuccess = createAction('[Sell Payloads Goals] Load Sell  Success',  props<{ payload: any }>());
export const loadGoalsError = createAction('[Sell Payloads Goals] Load Sell  Error',  props<{ payload: any }>());

export const clearDocumentId = createAction(  '[Sell Payloads Goals] ClearDocumentId');

export const sellPayloadsGoalsActions = {
  loadSignUrls,
  loadSignUrlsSuccess,
  loadSignUrlsError,

  upload,
  uploadSuccess,
  uploadError,

  loadGoals,
  loadGoalsSuccess,
  loadGoalsError,

  clearDocumentId
};
