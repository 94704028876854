import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {Router, RouterModule} from '@angular/router';
import {FirebaseError} from '@angular/fire/app';

import {AlertType, AppRoutes, RoleName} from '@common/constants';
import {BaseMixin} from '@components/_base';
import {RkButtonComponent} from '@components/shared/rk-button/rk-button.component';
import {RkInputComponent} from '@components/shared/rk-input/rk-input.component';
import {AlertService, AuthService} from '@services';
import {passwordValidator} from '@validators';
import {finalize, from, take, tap} from 'rxjs';
import jwt_decode from "jwt-decode";
import {Role} from "@common/types";


@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RouterModule, RkButtonComponent, RkInputComponent],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseMixin implements OnInit {
  private readonly _alert = inject(AlertService);
  private readonly _auth = inject(AuthService);
  private readonly _formBuilder = inject(FormBuilder);
  private readonly _router = inject(Router);

  private route = '';
  private roleRoute = new Map();

  signInForm = this._formBuilder.nonNullable.group({
    email: ['', {validators: [Validators.required, Validators.email]}],
    password: [
      '',
      {
        validators: [Validators.required],
      },
    ],
  });

  ngOnInit(): void {
    this._initializeMapRoute()
  }

  signIn() {
    if (this.signInForm.valid) {
      const {email, password} = this.signInForm.getRawValue();

      this._setBusy();

      from(this._auth.signIn(email, password))
        .pipe(
          take(1),
          finalize(() => this._setIdle()),
          tap(val => {
            const user = jwt_decode((val.user as any)['accessToken']) as any;
            this._setRoute(user.role as Role);
          })
        ).subscribe({
        next: () => this._router.navigate([this.route]),
        error: err => this._handleSignInError(err)
      })
    }
  }

  private _handleSignInError(err: unknown) {
    const text =
      (err as FirebaseError).code === 'auth/user-not-found' ||
      (err as FirebaseError).code === 'auth/wrong-password'
        ? 'Datos incorrectos, revisa tu correo electrónico y/o contraseña'
        : 'Error desconocido';

    this._alert.showAlert({
      duration: 5000,
      type: AlertType.ERROR,
      text,
    });
  }

  private _initializeMapRoute() {
    this.roleRoute.set(RoleName.HHRR, `/dashboard/${AppRoutes.GENERAL_LIQUIDATION_ADMIN}`)
    this.roleRoute.set(RoleName.ADMIN, `/dashboard/${AppRoutes.SELL_PAYLOADS}`)
    this.roleRoute.set(RoleName.SELLER, `/dashboard/${AppRoutes.GENERAL_LIQUIDATION_BY_EMPLOYEE}`)
    this.roleRoute.set(RoleName.TECHNICIAN, `/dashboard/${AppRoutes.GENERAL_LIQUIDATION_BY_EMPLOYEE}`)
    this.roleRoute.set(RoleName.VALIDATOR, `/dashboard/${AppRoutes.GENERAL_LIQUIDATION}`)
  }

  private _setRoute(role: Role) {
    this.route = this.roleRoute.get(role) ?? '';
  }
}
