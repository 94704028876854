import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Auth,
  User,
  confirmPasswordReset,
  onAuthStateChanged,
  signInAnonymously,
  signInWithEmailAndPassword,
  signOut,
  verifyPasswordResetCode,
} from '@angular/fire/auth';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import { ReplaySubject, filter, switchMap, take } from 'rxjs';

import { SignUpOrRecoveryMode as Mode } from '@common/constants';
import { Response, SignUpOrRecoveryMode } from '@common/types';
import { environment } from '@environments';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly _auth = inject(Auth);
  private readonly _firestore = inject(Firestore);
  private readonly _http = inject(HttpClient);

  private readonly _user = new ReplaySubject<User | null>(1);

  private readonly _signUpEndpoint = environment.endpoints.signUp;
  private readonly _recoveryPasswordEndpoint = environment.endpoints.recoveryPassword;

  constructor() {
    this._auth.tenantId = environment.tenantId;

    onAuthStateChanged(this._auth, (user) => {
      this._user.next(user);
      if (!user) void signInAnonymously(this._auth);
    });
  }

  private async _getClientId() {
    const ref = doc(this._firestore, 'config', 'client-id');
    return getDoc(ref).then((snap) =>
      localStorage.setItem('client-id', snap.data()?.['client-id'])
    );
  }

  private get _finalUrl() {
    return `${environment.baseURL}${environment.endpoints.auth}`;
  }

  get user() {
    return this._user.pipe(
      filter((user): user is User => !!user),
      take(1)
    );
  }

  init() {
    return this.user.pipe(switchMap(() => this._getClientId()));
  }

  signIn(email: string, password: string) {
    return signInWithEmailAndPassword(this._auth, email, password);
  }

  signUpOrRecovery(email: string, captcha: string, mode: SignUpOrRecoveryMode) {
    return this._http.post<Response<null>>(
      `${this._finalUrl}${
        mode === Mode.SIGN_UP ? this._signUpEndpoint : this._recoveryPasswordEndpoint
      }`,
      null,
      {
        headers: {
          'X-Captcha-Token': captcha,
        },
        params: {
          email,
        },
      }
    );
  }

  confirmPassword(oobCode: string, newPassword: string) {
    return confirmPasswordReset(this._auth, oobCode, newPassword);
  }

  verifyPasswordReset(code: string) {
    return verifyPasswordResetCode(this._auth, code);
  }

  signOut() {
    return signOut(this._auth);
  }
}
