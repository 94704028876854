export const dialogErrorMap: Map<string, string> = new Map<string, string>([
  ['clave','valor']
])

export const mapFieldName: Map<string, string> = new Map<string, string>(
  [
    ['beneficiary_rut', 'Rut ejecutivo beneficiado'],
    ['code', 'Código del vendedor'],
    ['contract_months', 'Meses de contrato'],
    ['contract_months', 'Meses de contrato'],
    ['last_sale_note_date', 'Fecha de guía de despacho'],
    ['last_sale_note_number', 'Número de guía de despacho'],
    ['last_bill_date', 'Fecha de factura'],
    ['last_bill_number', 'Número de factura'],
    ['note_number', 'Número de nota'],
    ['note_number_date', 'Fecha de nota de venta'],
    ['serial_number', 'Número de serie'],
    ['seller_rut', 'Rut del empleado'],
    ['sales_line', 'Linea de venta'],
    ['sale_type', 'Tipo de contrato']
  ]
)

export const mapReasonName: Map<string, string> = new Map<string, string>(
  [
    ['REQUIRED', 'No tiene'],
    ['INVALID', 'Inválida'],
    ['NO_MANAGER_RECORD', 'No existe en archivo manager'],
    ['SERIAL_NUMBER_MISMATCH', 'No coincide con el número de serie'],
    ['NO_DB_RECORD', 'No existe en base de datos'],
    ['OUT_OF_RANGE', 'No corresponde el periodo'],
    ['SERIAL_NUMBER_MISMATCH', 'No coincide con el número de serie'],
    ['WRONG_FORMAT', 'Formato incorrecto'],
    ['DUPLICATED', 'La nota de venta ya existe en el sistema'],
  ]
)

export const mapDialogErrors: Map<string, string> = new Map<string, string>(
  [
    ['business/blocked-by-liquidations','Ya existen liquidaciones calculadas en este período.'],
    ['auth/no-employee-record','El empleado no existe en base de datos.'],
    ['error/send-mail-failed','No se pudo enviar el correo.'],
    ['business/no-open-period','No hay  un período abierto.'],
    ['business/out-of-range-date','Fecha fuera de rango.'],
    ['internal/upload-in-progress','Proceso de carga en progreso.'],
    ['auth/unauthenticated','Usuario no autenticado.'],
    ['error/unknown','Error desconocido.'],
    ['auth/insufficient-permissions','No tiene permisos suficientes para realizar esta acción.'],
    ['auth/captcha-verify-fialied','Verificación fallida.'],
    ['error/validation-failed','Validación fallida, hay valores en campos no requeridos.'],
    ['business/period-is-closed','El período está cerrado.'],
    ['business/period-is-open','El período está abierto.'],
    ['auth/user-already-exists','El usuario ya existe.'],
    ['business/pending-liquidations','Liquidaciones pendientes.'],
    ['error/invalid-type','No válido.'],
    ['auth/no-bearer-token','No tiene autorización.'],
    ['business/out-of-bounds-records','Fuera del límite de los registros.'],
    ['auth/no-captcha-token','Sin credenciales de autenticación.'],
    ['DUPLICATED', 'La nota de venta ya existe en el sistema'],
  ]
)
