import {createAction, props} from '@ngrx/store';
import {
  APIResponse,
  APIResponseForUser,
  APIResponsePeriodPending,
  ProcessSummary,
  RunningProcess
} from '@common/models';
import {DocumentData} from '@angular/fire/firestore';

const hello = createAction('[RunProcess Feature]', props<{payload: string}>());

/*
* Request information about current period
 endpoint: process/period/pending
* */
const getPeriodDate = createAction('[RunProcess -> Period Date] Request ');
const getPeriodDateSuccess = createAction('[RunProcess -> Period Date] Success', props<{payload: APIResponsePeriodPending}>());
const getPeriodDateError = createAction('[RunProcess -> Period Date] Error', props<{payload: any}>());

/*
* Request information about logs
 endpoint: process/logs
* */
const getProcessLogs = createAction('[RunProcess ->Logs] Request ');
const getProcessLogsSuccess = createAction('[RunProcess ->Logs] Success', props<{payload: APIResponse<RunningProcess>}>());
const getProcessLogsError = createAction('[RunProcess ->Logs] Error', props<{payload: any}>());

/*
* Request information about logs
 endpoint: process/period/summary
* */
const getProcessPeriodSummary = createAction('[RunProcess ->PeriodSummary] Request ');
const getProcessPeriodSummarySuccess = createAction('[RunProcess ->PeriodSummary] Success', props<{payload: APIResponseForUser<ProcessSummary>}>());
const getProcessPeriodSummaryError = createAction('[RunProcess ->PeriodSummary] Error', props<{payload: any}>());

/*
* Request run process
 endpoint: process
* */
const runProcess = createAction('[RunProcess ->] Request ');
const runProcessSuccess = createAction('[RunProcess ->] Success', props<{payload: APIResponse<any> | DocumentData}>());
const runProcessError = createAction('[RunProcess ->] Error', props<{payload: any}>());
const saveProcessSuccess = createAction('[RunProcess ->] Save Success', props<{
  payload: APIResponse<any> | DocumentData;documentReference: string;}>());

// Document in firebase
const deleteDocument = createAction('[RunProcess ->] Delete document', props<{payload: string;}>());
const deleteDocumentError = createAction('[RunProcess ->] Delete document', props<{payload: any;}>());
const clearDocumentReference = createAction('[RunProcess ->] Clear document reference');

/*
* Request close period
 endpoint: process
* */
const closePeriod = createAction('[RunProcess -> Notify] Request close');
const closePeriodSuccess = createAction('[RunProcess -> Notify] Close Success', props<{payload: any}>());
const closePeriodError = createAction('[RunProcess -> Notify] Error', props<{payload: any}>());

/*
* Request run process
 endpoint: process/period/close
* */
const sendProcess = createAction('[RunProcess -> Trigger Actions] Request send process');
const sendProcessSuccess = createAction('[RunProcess -> Trigger Actions] Request send Success', props<{payload: APIResponse<any>}>());
const sendProcessError = createAction('[RunProcess -> Trigger Actions] Request send Error', props<{payload: any}>());


/*
* Request information about current period for all and avoid all others requests
 endpoint: process/period/pending
* */
const loadPeriodDate = createAction('[Common -> Load Period Date] Request ');
const loadPeriodDateSuccess = createAction('[Common -> Load Period Date] Success', props<{payload: APIResponsePeriodPending}>());
const loadPeriodDateError = createAction('[Common -> Load Period Date] Error', props<{payload: any}>());
const noop = createAction('[RunProcess ->Noop]');

export const runProcessActions = {
  hello,
  getPeriodDate,
  getPeriodDateSuccess,
  getPeriodDateError,

  getProcessLogs,
  getProcessLogsSuccess,
  getProcessLogsError,

  getProcessPeriodSummary,
  getProcessPeriodSummarySuccess,
  getProcessPeriodSummaryError,

  runProcess,
  runProcessSuccess,
  runProcessError,
  saveProcessSuccess,

  deleteDocument,
  deleteDocumentError,
  clearDocumentReference,

  closePeriod,
  closePeriodSuccess,
  closePeriodError,

  sendProcess,
  sendProcessSuccess,
  sendProcessError,

  loadPeriodDate,
  loadPeriodDateSuccess,
  loadPeriodDateError,

  noop
}
