<div>
  <span
    mat-dialog-close
    class="material-symbols-outlined m-2 ml-auto block w-fit cursor-pointer text-3xl/none text-slate-gray"
    (click)="close(false)"
  >close</span
  >
  <div class="p-[40px] pt-1">

      <div class="font-medium text-[20px] text-charcoal-500">{{data.title}}</div>
      <ul class="list-disc ml-4 mt-6">
        <li *ngFor="let data of data.extraData">{{data.names}} {{data.last_name}}</li>
      </ul>

  </div>
  <div *ngIf="data.buttons?.length" class="flex justify-center gap-4 p-6 pt-0">
    <rk-button
      *ngFor="let button of data.buttons"
      [label]="button.label"
      [stroked]="button.stroked!"
      (doClick)="close(button.result)"
      [extraClasses]="'!min-w-[165px] !max-h-[50px] !bg-white !text-french-blue !border !border-french-blue !border-solid text-[14px] fw-medium'"
    >
    </rk-button>

  </div>
</div>
