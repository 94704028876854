import {inject} from '@angular/core';
import {catchError, map, of, switchMap} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {generalInformationActions} from '@features/general-information/store/general-information.actions';
import {ValidationService} from '../../../core/services/validation.service';
import {FullResponseError} from '@common/models';
import {mapDialogErrors} from "../../../core/maps/maps-error-msg";


export const getValidationsEffects = createEffect(
  (actions$ = inject(Actions), validationService = inject(ValidationService)) => {
    return actions$.pipe(
      ofType(generalInformationActions.loadValidations),
      switchMap(() =>
        validationService.get().pipe(
            map((payload) => generalInformationActions.loadValidationsSuccess({ payload })),
            catchError((error) =>
              of(generalInformationActions.loadValidationsError({ payload: mapDialogErrors.get(error.code) ?? 'Error desconocido' }))
            )
          )
      )
    );
  },
  { functional: true }
);
export const getValidationsSellerEffects = createEffect(
  (actions$ = inject(Actions), validationService = inject(ValidationService)) => {
    return actions$.pipe(
      ofType(generalInformationActions.loadValidationsSeller),
      switchMap((action) =>
        validationService.getBySellers(action.payload).pipe(
            map((result) => generalInformationActions.loadValidationsSellerSuccess({ payload: result })),
            catchError((error: FullResponseError) =>
              of(generalInformationActions.loadValidationsSellerError({ payload: error.error }))
            )
          )
      )
    );
  },
  { functional: true }
);
export const getValidationsFilterEffects = createEffect(
  (actions$ = inject(Actions), validationService = inject(ValidationService)) => {
    return actions$.pipe(
      ofType(generalInformationActions.loadValidationsFilter),
      switchMap((action) =>
        validationService.getFilterData().pipe(
            map((result) => generalInformationActions.loadValidationsFilterSuccess({ payload: result })),
            catchError((error) =>
              of(generalInformationActions.loadValidationsFilterError({ payload: mapDialogErrors.get(error.code) ?? 'Error desconocido' }))
            )
          )
      )
    );
  },
  { functional: true }
);

// export const displayErrorAlert = createEffect(
//   () => {
//     return inject(Actions).pipe(
//       ofType(ActorsApiActions.actorsLoadedFailure),
//       tap(({ errorMsg }) => alert(errorMsg))
//     );
//   },
//   { functional: true, dispatch: false }
// );
