import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { SnackAlertData } from '@common/types';

@Component({
  selector: 'app-snack-alert',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './snack-alert.component.html',
  styleUrls: ['./snack-alert.component.scss'],
})
export class SnackAlertComponent {
  data: SnackAlertData = inject(MAT_SNACK_BAR_DATA);
}
