import {Routes} from '@angular/router';
import {isNotAnonymous} from '@angular/fire/auth-guard';
import {map, pipe} from 'rxjs';

import {AuthComponent, LoginComponent, ResetPasswordComponent, SignUpOrRecoveryComponent,} from '@components/auth';
import {canActivate, oobCodeGuard} from '@guards';
import {ActionReducer, MetaReducer, provideState} from '@ngrx/store';
import {initialState, runProcessFeatureKey, runProcessReducer} from '@features/run-process/store/process.reducer';
import {provideEffects} from '@ngrx/effects';
import {
  closePeriodAlertEffects,
  closePeriodEffects,
  closePeriodErrorAlertEffects,
  deleteDocumentEffects,
  getPeriodDateEffects,
  getProcessLogsAfterDateEffects,
  getProcessLogsDateEffects,
  getProcessPeriodSummaryEffects,
  loadPeriodDateEffects, reloadCNotifyAfterClosePeriodEffects,
  reloadCNotifyAfterDocumentClearEffects,
  reloadLogsAfterDocumentClearEffects,
  runProcessEffects,
  saveProcessEffects,
  sendProcessEffects,
  sendProcessErrorAlertEffects,
  sendProcessSuccessAlertEffects
} from '@features/run-process/store/process.effects';
import {
  getValidationsEffects,
  getValidationsFilterEffects,
  getValidationsSellerEffects
} from '@features/general-information/store/general-information.effects';
import {
  loadSellNotesEffects,
  onGetSignsUrlsEffects,
  periodNextEffects,
  uploadEffects
} from '@features/sell-payloads/store/sell-payloads.effects';
import {
  loadEmployeesEffects,
  onGetSignsUrlsEmployeeEffects,
  uploadEmployeeEffects
} from '@features/sell-payloads-employee/store/sell-payloads-employee.effects';
import {
  loadEmployeeInformationEffects,
  loadSellerTypeCommissionSummaryEffects, loadSettlementsAvailableEffects,
  loadSettlementsEffects
} from '@features/general-liquidation-by-employee/store/employee-information.effects';
import {dashboardResolver} from './resolvers/dashboard.resolvers';
import {LOGOUT} from '@components/dashboard/general.actions';
import {
  loadGoalsEmployeesEffects, onGetSignsUrlsGoalsEffects,
  uploadGoalsEffects
} from '@features/sell-payloads-goals/store/sell-payloads-goals.effects';

export const TITLE_APP = 'Rentokil'

const redirectAnonymousTo = (redirect: unknown[]) =>
  pipe(
    isNotAnonymous,
    map((notAnonymous) => notAnonymous || redirect)
  );

const redirectNotAnonymousTo = (redirect: unknown[]) =>
  pipe(
    isNotAnonymous,
    map((notAnonymous) => !notAnonymous || redirect)
  );

const redirectAnonymousToLogin = () => redirectAnonymousTo(['auth/login']);
const redirectNotAnonymousToDashboard = () => redirectNotAnonymousTo(['']);

export function clearStateMetaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return function clearStateFn(state, action) {
    if (action.type === LOGOUT) {
      state = {...initialState}; // ==> Emptying state here
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [clearStateMetaReducer];

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./components/dashboard/dashboard.routes').then(mod => mod.dashboardRoutes),
    ...canActivate(redirectAnonymousToLogin),
    providers: [
      provideState(runProcessFeatureKey, runProcessReducer, {metaReducers} ),
      provideEffects({getPeriodDateEffects, getProcessLogsAfterDateEffects,
        getProcessPeriodSummaryEffects, runProcessEffects, saveProcessEffects, deleteDocumentEffects,
        reloadLogsAfterDocumentClearEffects, reloadCNotifyAfterClosePeriodEffects, getProcessLogsDateEffects, closePeriodEffects, closePeriodAlertEffects,
        closePeriodErrorAlertEffects, sendProcessEffects, sendProcessSuccessAlertEffects, sendProcessErrorAlertEffects,
        reloadCNotifyAfterDocumentClearEffects,


        getValidationsEffects, getValidationsSellerEffects, getValidationsFilterEffects,

        uploadEffects, onGetSignsUrlsEffects, periodNextEffects,

        uploadEmployeeEffects, onGetSignsUrlsEmployeeEffects,

        /*Efectos de liquidacion de empleados*/
        loadEmployeeInformationEffects, loadSellerTypeCommissionSummaryEffects, loadSettlementsEffects, loadSettlementsAvailableEffects,

        /*Efectos para todos los roles*/
        loadPeriodDateEffects,

        /*Efectos para payload empleados*/
        loadEmployeesEffects,

        /*Efectos para payload goals*/
        loadGoalsEmployeesEffects, uploadGoalsEffects, onGetSignsUrlsGoalsEffects,

        /*Efectos para payload*/
        loadSellNotesEffects
      })
    ],
    resolve: {
      periodDate: dashboardResolver
    }
  },
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent, title: TITLE_APP },
      { path: 'recovery-password', component: SignUpOrRecoveryComponent, title: TITLE_APP },
      { path: 'reset-password', component: ResetPasswordComponent, canActivate: [oobCodeGuard], title: TITLE_APP },
      { path: 'sign-up', component: SignUpOrRecoveryComponent, title: TITLE_APP },
    ],
    ...canActivate(redirectNotAnonymousToDashboard),
  },
];
