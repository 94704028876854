import {Injectable, isDevMode} from '@angular/core';
import {HttpBackend, HttpClient, HttpErrorResponse} from '@angular/common/http';

import {map, Observable, throwError} from 'rxjs';
import {environment} from '@environments';
import {APIPeriodNextResponse, APIResponseSignURLs, SignUrlsParams, SignUrlsWithFiles} from '@common/models';

const BASE_URL = environment.baseURL;

// const BASE_URL = 'https://dev-api.rentokil.seiza-ti.cl/api/v1/';

@Injectable({
  providedIn: 'root'
})
export class SellPayloadsService {

  constructor(private _httpClient: HttpClient,
              private _handler: HttpBackend) {
  }

  getPeriodNext(): Observable<APIPeriodNextResponse> {
    return this._httpClient.get<APIPeriodNextResponse>(      `${BASE_URL}process/period/next`);
  }

  loadSignUrls(params: SignUrlsParams): Observable<SignUrlsWithFiles> {
    return this._httpClient.get<APIResponseSignURLs>(
      `${BASE_URL}upload/sales?managers=${params.files.length - 1}&month=${params.month}&year=${params.year}`)
      .pipe(
        map(response => ({
          files: params.files,
          urls: response.data
        }))
      );
  }

  // uno solo
  loadSignUrlsEmployee(params: SignUrlsParams): Observable<SignUrlsWithFiles> {
    return this._httpClient.get<APIResponseSignURLs>(
      `${BASE_URL}upload/sales?managers=${params.files.length - 1}&month=${params.month}&year=${params.year}`)
      .pipe(
        map(response => ({
          files: params.files,
          urls: response.data
        }))
      );
  }

  uploadToCloud(data: { url: string; file: File }): Observable<any> {
    const formData = new FormData();
    formData.append('file', data.file);
    const httpClient = new HttpClient(this._handler);

    // const BYPASS_LOG = new HttpContextToken(() => false);
    return httpClient.put<any>(data.url, data.file );
    // if (data.file) {
    //   return this.storage.upload(data.url, data.file);
    // }
  }

  loadSaleNotes(payload: { month: number; year: number; }): Observable<any> {
    let query = ``;
    if(payload.month) {
      query += `&month=${payload.month}`;
    }
    if(payload.year) {
      query += `&year=${payload.year}`
    }
    return this._httpClient.get<any>(`${BASE_URL}data/sale-note?${query}`)
  }

  loadSaleNotesErrors(payload?: { month: number; year: number; }): Observable<any> {
    let query = ``;
    if(payload?.month) {
      query += `&month=${payload.month}`;
    }
    if(payload?.year) {
      query += `&year=${payload.year}`
    }
    return this._httpClient.get<any>(`${BASE_URL}data/sale-note-errors?${query}`)
  }

  private handleError(error: HttpErrorResponse) {
    let text = '';
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      text = error.message;
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      text = `Backend returned code ${error.status}, body was: ${error.message}`;
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    if (!isDevMode()) {
      text = 'Something bad happened; please try again later.'
    }
    return throwError(() => new Error(text));
  }
}
