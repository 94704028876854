import { ModalMetadata } from '@common/types';
import { AlertType as ModalType } from '@common/constants';
export const confirmCancelProcessMetadata: ModalMetadata = {
  type: ModalType.ERROR,
  title: '¿Estás seguro de que quieres cancelar el proceso de cálculo de comisiones?',
  text: '',
  buttons: [
    {
      label: 'No, volver',
      extraClasses:
        '!bg-white !text-french-blue !border !border-french-blue !border-solid text-[14px] fw-medium',
    },
    { label: 'Sí, cancelar' },
  ],
};

export const confirmRunProcessMetadata: ModalMetadata = {
  type: ModalType.ERROR,
  title: '¿Estás seguro de que quieres ejecutar el proceso de cálculo de comisiones?',
  text: '',
  buttons: [
    {
      label: 'No, volver',
      extraClasses:
        '!bg-white !text-french-blue !border !border-french-blue !border-solid text-[14px] fw-medium',
      result: false,
    },
    {
      label: 'Sí, ejecutar',
      result: true,
    },
  ],
  extraCss: 'confirmation',
};

export const genericErrorMetadata: ModalMetadata = {
  type: ModalType.ERROR,
  title: 'No pudimos realizar tu solicitud, por favor inténtalo de nuevo',
  text: '',
  buttons: [
    {
      label: 'Cerrar',
      result: true,
    },
  ]
};
