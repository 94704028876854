import {inject} from '@angular/core';
import {ResolveFn} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {filter, take} from 'rxjs/operators';
import {runProcessActions} from '@features/run-process/store/process.actions';
import {selectRunProcessDateLoading} from '@features/run-process/store/process.selector';

export const dashboardResolver: ResolveFn<boolean> = () => {
  const store = inject(Store);
  const loaded$ = store.pipe(select(selectRunProcessDateLoading));
  return loaded$.pipe(
    filter(loaded => {
      if (!loaded) {
        store.dispatch(runProcessActions.loadPeriodDate());
      }
      return loaded;
    }),
    take(1)
  );
};
